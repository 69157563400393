import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Table, Spinner, Button, DetailsPanel } from 'components';
import { routers } from 'settings';
import { logger } from 'helpers';
import manageProvider from '../Configs/manageProvider';
import blockedUsers from '../Configs/blockedUsers';
import { Back, EditIcon as Edit } from 'components/Icons';
// TrashBin
import s from '../style.module.scss';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';

let PATIENT_DETAILS_FIELDS = [
	{
		key: 'email_address',
		label: 'Provider Email-Id',
		type: 'text',
		translateKey: 'provider_email_id',
	},
	{
		key: 'phone_number',
		label: 'Phone Number',
		type: 'text',
		translateKey: 'provider_phone',
	},
	{
		key: 'customer_care_number',
		label: 'Customer Care Number',
		type: 'text',
		translateKey: 'customer_care_number',
	},
	{
		key: 'address_1',
		label: 'Address',
		type: 'text',
		translateKey: 'address',
	},
	{
		key: 'postal_code',
		label: 'PIN Code',
		type: 'text',
		translateKey: 'pinCode',
	},
];
class Provider extends Component {
	componentDidMount() {
		logger('[ManageUsers Provider] componentDidMount');

		const { data, match, onInit, clear } = this.props;

		clear();

		if (!this.isLoaded) {
			this.isLoaded = data;
		}

		if (match.params.id === '1') {
			this.isInogen = true;
		}

		onInit(match.params.id);
	}


	render() {
		const {
			data,
			usersPendingData,
			currentUser,
			history,
			match,
			changeUsersPendingStatus,
			changeManageUsersStatus,
			openModal,
		} = this.props;

		let providerView = <Spinner />;
		if (data) {
			const usersByRoles = data.users_by_roles;
			const blockedUsersData = usersPendingData && usersPendingData.filter((el) => !el.status);
			const isBlocked = data?.is_blocked === true;
			const statusFontcolor = isBlocked ? '#DF0C0C' : '#6C7DDF';
			const statusText = isBlocked ? 'unblock' : 'block';
			const fillColor = isBlocked ? '#DF0C0C' : '#6C7DDF';
			const pathId = isBlocked ? 'Subtract' : 'Vector';
			const pathData = isBlocked ? 'M9.08952 20.752C10.2457 21.2506 11.4807 21.5 12.7946 21.5C14.1086 21.5 15.3433 21.2505 16.4989 20.7516C17.6546 20.2527 18.6598 19.5757 19.5147 18.7205C20.3695 17.8652 21.0463 16.8596 21.5449 15.7034C22.0436 14.5473 22.2929 13.3122 22.2929 11.9983C22.2929 10.6844 22.0435 9.44959 21.5446 8.29398C21.0457 7.13834 20.3687 6.1331 19.5134 5.27825C18.6582 4.42342 17.6525 3.74667 16.4964 3.248C15.3402 2.74933 14.1052 2.5 12.7913 2.5C11.4773 2.5 10.2426 2.74944 9.08694 3.24833C7.93131 3.74721 6.92607 4.42427 6.07122 5.2795C5.21639 6.13472 4.53964 7.1404 4.04097 8.29655C3.5423 9.45268 3.29297 10.6877 3.29297 12.0017C3.29297 13.3156 3.54241 14.5504 4.04129 15.706C4.54018 16.8616 5.21724 17.8669 6.07247 18.7217C6.92769 19.5765 7.93337 20.2533 9.08952 20.752ZM18.4679 17.675C16.9179 19.225 15.0263 20 12.7929 20C11.8547 20 10.9513 19.8413 10.0825 19.524C9.21383 19.2067 8.41985 18.7474 7.70062 18.1461L9.7843 16.0624L8.73048 15.0086L6.64679 17.0923C6.04551 16.3731 5.58622 15.5791 5.26892 14.7104C4.9516 13.8417 4.79294 12.9382 4.79294 12C4.79294 9.76664 5.56794 7.87498 7.11794 6.32498C8.66794 4.77498 10.5596 3.99998 12.7929 3.99998C13.7334 3.99998 14.639 4.15703 15.5098 4.47113C16.3806 4.78524 17.1725 5.24614 17.8853 5.85383L15.8015 7.93755L16.8554 8.99137L18.9391 6.90765C19.534 7.62688 19.9917 8.42086 20.3122 9.28958C20.6327 10.1583 20.7929 11.0618 20.7929 12C20.7929 14.2333 20.0179 16.125 18.4679 17.675Z'
				: 'M12.7946 21.5C11.4807 21.5 10.2457 21.2506 9.08952 20.752C7.93337 20.2533 6.92769 19.5765 6.07247 18.7217C5.21724 17.8669 4.54018 16.8616 4.04129 15.706C3.54241 14.5504 3.29297 13.3156 3.29297 12.0017C3.29297 10.6877 3.5423 9.45268 4.04097 8.29655C4.53964 7.1404 5.21639 6.13472 6.07122 5.2795C6.92607 4.42427 7.93131 3.74721 9.08694 3.24833C10.2426 2.74944 11.4773 2.5 12.7913 2.5C14.1052 2.5 15.3402 2.74933 16.4964 3.248C17.6525 3.74667 18.6582 4.42342 19.5134 5.27825C20.3687 6.1331 21.0457 7.13834 21.5446 8.29398C22.0435 9.44959 22.2929 10.6844 22.2929 11.9983C22.2929 13.3122 22.0436 14.5473 21.5449 15.7034C21.0463 16.8596 20.3695 17.8652 19.5147 18.7205C18.6598 19.5757 17.6546 20.2527 16.4989 20.7516C15.3433 21.2505 14.1086 21.5 12.7946 21.5ZM12.7929 20C15.0263 20 16.9179 19.225 18.4679 17.675C20.0179 16.125 20.7929 14.2333 20.7929 12C20.7929 11.0618 20.6327 10.1583 20.3122 9.28958C19.9917 8.42086 19.534 7.62688 18.9391 6.90765L7.70062 18.1461C8.41985 18.7474 9.21383 19.2067 10.0825 19.524C10.9513 19.8413 11.8547 20 12.7929 20ZM6.64679 17.0923L17.8853 5.85383C17.1725 5.24614 16.3806 4.78524 15.5098 4.47113C14.639 4.15703 13.7334 3.99998 12.7929 3.99998C10.5596 3.99998 8.66794 4.77498 7.11794 6.32498C5.56794 7.87498 4.79294 9.76664 4.79294 12C4.79294 12.9382 4.9516 13.8417 5.26892 14.7104C5.58622 15.5791 6.04551 16.3731 6.64679 17.0923Z';

			providerView = (
				<Fragment>
					<div className={s.providerWrapper}>
						<div className={s.providerTitle}>
							<div className={s.displayFlex}>
								<Link className={s.backBtn} to={routers.users}>
									<span>
										<Back width='40' fill='#000' height='40' />
									</span>
								</Link>
								<span className={[s.pageTitle, s.providerTitle].join(' ')}>
									{data.name || i18next.t('provider_name')}
								</span>
							</div>
							<div className={s.displayFlex}>
							{data?.is_inogen === true ? (
									''
								) : (
								<div
									className={s.blockButtonDiv}
									tabIndex={0}
									onClick={() =>
										openModal({
											type: 'BlockProvider',
											data: data,
											restrictClosing: true
										})
									}
								>
									<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
										<g id="Icon/Block">
											<path id={pathId} fillRule="evenodd" clipRule="evenodd" d={pathData} fill={fillColor} />
										</g>
									</svg>
									<span style={{ marginLeft: '5px', color: statusFontcolor }}>
										{i18next.t(statusText)}
									</span>

								</div>)}

								<Button
									className={s.editButton}
									btnType='type-20'
									clicked={() =>
										history.push(routers.usersProviderEdit(match.params.id))
									}
								>
									{i18next.t('edit')}
									<Edit
										fill={'white'}
										style={{ float: 'left', marginLeft: '8px' }}
									/>
								</Button>
							</div>
						</div>
						<div className={s.providerDetails}>
							<DetailsPanel
								mapper={PATIENT_DETAILS_FIELDS}
								data={data}
								style={{
									boxShadow: '0px 4px 8px 0px rgba(0,0,0,0.2)',
								}}
							/>
						</div>
						{usersByRoles && !Array.isArray(usersByRoles) ? (
							Object.keys(data.users_by_roles).map((el, index) => {
								if (usersByRoles[el].length) {
									return (
										<Fragment key={index}>
											<div className={s.tableContent}>
												<h2 className={s.listTitle}>
													{i18next.t(el)} ({usersByRoles[el].length})
												</h2>
												<Table
													className={[s.largeTable, s.providerTable].join(' ')}
													config={manageProvider}
													data={usersByRoles[el]}
													currentUser={currentUser}
													changeUsersStatus={changeManageUsersStatus}
													onRowClicked={(row, index) =>
														openModal({
															type: 'UserDetails',
															subType: 'byProvider',
															data: row,
															index: index,
															restrictClosing: true,
														})
													}
												/>
											</div>
										</Fragment>
									);
								} else {
									return null;
								}
							})
						) : (
							<p className={s.noUsersText}>
								{i18next.t('there_are_no_users_for_this_provider')}
							</p>
						)}

						{this.isInogen && (
							<Fragment>
								<h2 className={s.listTitle}>{i18next.t('blocked')}</h2>
								<Table
									className={[s.largeTable, s.providerTable].join(' ')}
									config={blockedUsers}
									data={blockedUsersData}
									currentUser={currentUser}
									changeUsersPendingStatus={changeUsersPendingStatus}
									onRowClicked={(row) =>
										openModal({ type: 'PendingRequest', data: row })
									}
								/>
							</Fragment>
						)}
					</div>
				</Fragment>
			);
		}

		return <Fragment>{providerView}</Fragment>;
	}
}

Provider.defaultProps = {
	data: {},
};

Provider.propTypes = {
	data: PropTypes.object,
};

export default withTranslation()(Provider);
