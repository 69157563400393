export const MODEL_IDS = {
	'Inogen One G4': 10,
	'Inogen One G5': 11,
	'Inogen Rove 4': 12,
	'Inogen Rove 6': 13,
	'OxyGo FIT': 14,
	'OxyGo NEXT': 15,
};

export const DeviceFirmwaresConfig = [
	{
		id: 1,
		model_id: 10,
		hardwareVersionDropdownValues: [2],
	},
	{
		id: 2,
		model_id: 11,
		hardwareVersionDropdownValues: [1],
	},
	{
		id: 3,
		model_id: 12,
		hardwareVersionDropdownValues: [1],
	},
	{
		id: 4,
		model_id: 13,
		hardwareVersionDropdownValues: [1, 2],
	},
	{
		id: 5,
		model_id: 14,
		hardwareVersionDropdownValues: [2],
	},
	{
		id: 6,
		model_id: 15,
		hardwareVersionDropdownValues: [1],
	},
];
