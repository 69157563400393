import React, { Component } from 'react';
import s from './style.module.scss';
import i18next from 'i18next';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { ErrorIcon } from 'components/Icons';

class AdvanceEngineeringData extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const { data, engineeringVals, Time, Description } = this.props;

		const translatedData = data &&
							data.data &&
							Array.isArray(data.data) &&
							data.data.length > 0
								? data.data
									.map((item) => ({
										...item,
										label: i18next.t(item.label || ''),
									}))
									.sort((a, b) => (a.label || '').localeCompare(b.label || ''))
								: [];

		const first8Data =
			translatedData && translatedData.length > 0 && translatedData.slice(0, 9);
		const remainingData =
			translatedData && translatedData.length > 0 && translatedData.slice(9);

		const timing =
			remainingData?.[8]?.label === 'Life Clock'
				? engineeringVals?.data?.[0]?.life_clock || ''
				: '';
		const hrs = Math.floor(timing / 60);
		const min = timing % 60;
		const time = `${hrs ? hrs : '0'} ${i18next.t('hrs')} ${
			min ? min : '0'
		} ${i18next.t('min')}`;

		const totalMinutes = Time;
		const hours = Math.floor(totalMinutes / 60);
		const minutes = totalMinutes % 60;
		let result;
		if (hours === 0) {
			result = `${minutes} ${i18next.t('minutes')}`;
		} else {
			result = `${hours} ${i18next.t('hours')} ${minutes} ${i18next.t(
				'minutes',
			)}`;
		}
		return (
			<div>
				<div className={s.main_form}>
					<div className={s.table_container}>
						<div className={s.table}>
							<table className={s.tables}>
								<tbody className={s.table_head}>
									<tr className={s.tableBorder}>
										<td className={s.table_head_styling}>
											{i18next.t('parameters')}
										</td>
										<td className={s.table_head_styling}>
											{i18next.t('values')}
										</td>
									</tr>
								</tbody>
								<tbody>
									{first8Data &&
										first8Data.map((item) => (
											<tr className={s.trow} key={item.code_string}>
												<td className={s.table_data}>{item.label}</td>
												<td className={s.table_data_value}>
													{engineeringVals?.data?.[0]?.[item?.code_string] ||
														'N/A'}
												</td>
											</tr>
										))}
								</tbody>
							</table>
						</div>
						<div className={s.table}>
							<table className={s.tables}>
								<tbody className={s.table_head}>
									<tr className={s.tableBorder}>
										<td className={s.table_head_styling}>
											{i18next.t('parameters')}
										</td>
										<td className={s.table_head_styling}>
											{i18next.t('values')}
										</td>
									</tr>
								</tbody>
								<tbody>
									{remainingData &&
										remainingData.map(
											(item) =>
												item.id !== 11 && (
													<tr className={s.trow} key={item.code_string}>
														<td className={s.table_data}>{item.label}</td>
														<td className={s.table_data_value}>
															{item.label === 'Life Clock'
																? time
																: engineeringVals?.data?.[0]?.[
																		item?.code_string
																  ] || 'N/A'}
														</td>
													</tr>
												),
										)}
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<div className={s.ppdBottom1}>
					<div className={s.ppdWrapper1}>
						<div className={s.box}>
							<div className={s.ppdLabel1}>
								{i18next.t('error_recall')}{' '}
								<ErrorIcon style={{ marginLeft: '16px' }} />
							</div>
						</div>
						<div className={s.boxData}>
							<div className={s.ppdLabel2}>
								{Description ? i18next.t(Description) : 'N/A'}
							</div>
						</div>
						<div className={s.boxData}>
							<div className={s.ppdLabel3}>{result ? result : ''}</div>
						</div>
						<div></div>
					</div>
				</div>
			</div>
		);
	}
}

export default connect()(withTranslation()(AdvanceEngineeringData));
