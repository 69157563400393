import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Input, Button, Spinner } from 'components';
import { Back } from 'components/Icons';
import { logger, checkValidity } from 'helpers';
import { routers } from 'settings';
import { Country, State } from 'country-state-city';
import Select, { components } from 'react-select';
import { countryConstants } from '../Configs/constants';
import s from '../style.module.scss';
import i18next from 'i18next';

const DropdownIndicator = (props) => {
	return (
		<components.DropdownIndicator {...props}>
			<svg
				width='10'
				height='5'
				viewBox='0 0 10 5'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<path d='M0 0L5 5L10 0H0Z' fill='#999999' />
			</svg>
		</components.DropdownIndicator>
	);
};
const customStyles = {
	control: (provided) => ({
		...provided,
	}),
	// menu: (provided, state) => ({
	// 	...provided,
	// backgroundColor: "red",
	// ":hover": {
	// 	backgroundColor: "green",
	// },
	// }),
	indicatorSeparator: (provider) => ({
		...provider,
		marginTop: '0px',
		marginBottom: '0px',
	}),
};
class ProviderEdit extends Component {
	constructor(props) {
		super(props);
		const defaultCountry =
			Country.getAllCountries().find(
				(country) => country.name === props?.data?.country,
			) || null;
		const defaultState =
			defaultCountry &&
			State.getStatesOfCountry(defaultCountry.isoCode).find(
				(state) => state.name === props?.data?.state,
			);
		const phoneNumber = props.data ? props.data.phone_number : '';
		const hyphenIndex = phoneNumber ? phoneNumber.indexOf('-') : phoneNumber;
		const countryCode =
			hyphenIndex !== -1
				? phoneNumber
					? phoneNumber.slice(0, hyphenIndex)
					: []
				: phoneNumber;
		const PhoneNumberField =
			hyphenIndex !== -1
				? phoneNumber
					? phoneNumber.slice(hyphenIndex + 1)
					: []
				: phoneNumber;

		const custphoneNumber = props.data ? props.data.customer_care_number : '';
		const Index = custphoneNumber
			? custphoneNumber.indexOf('-')
			: custphoneNumber;
		const custPhoneNumberField =
			Index !== -1
				? custphoneNumber
					? custphoneNumber.slice(Index + 1)
					: []
				: custphoneNumber;

		this.state = {
			controls: {
				address_1: {
					elementType: 'input',
					elementConfig: {
						type: 'text',
						placeholder: i18next.t('address_line1'),
					},
					className: s.input,
					value: props.data ? props.data.address_1 : '',
					validation: {
						required: true,
						isaddress: true,
						maxLength: 100,
					},
					valid: props.data && props.data.address_1 ? true : false,
					errorMsg: 'invalid_address',
					touched: false,
				},
				address_2: {
					elementType: 'input',
					elementConfig: {
						type: 'text',
						placeholder: i18next.t('address_line2'),
					},
					className: s.input,
					value: props.data ? props.data.address_2 : '',
					validation: {
						required: false,
						isaddressoptional: true,
						maxLength: 100,
					},
					valid: true,
					errorMsg: 'invalid_address',
					touched: true,
				},
				phone_number: {
					elementType: 'input',
					elementConfig: {
						type: 'text',
						placeholder: i18next.t('provider_phone'),
					},
					className: s.input,
					value: PhoneNumberField ? PhoneNumberField : '',
					validation: {
						required: true,
						isPhone: true,
					},
					valid: props.data && props.data.phone_number ? true : false,
					errorMsg: 'invalid_phone',
					touched: false,
				},
				fax: {
					elementType: 'input',
					elementConfig: {
						type: 'text',
						placeholder: i18next.t('fax'),
					},
					className: s.input,
					value: props.data ? props.data.fax : '',
					validation: {
						required: false,
						isfax: true,
					},
					valid: true,
					errorMsg: 'invalid_fax',
					touched: false,
				},
				email_address: {
					elementType: 'input',
					elementConfig: {
						type: 'text',
						placeholder: i18next.t('provider_email'),
					},
					className: s.input,
					value: props.data ? props.data.email_address : '',
					validation: {
						isEmail: true,
						required: true,
					},
					valid: props.data && props.data.email_address ? true : false,
					errorMsg: 'auth_invalid_email_name',
					touched: false,
				},
				website_url: {
					elementType: 'input',
					elementConfig: {
						type: 'text',
						placeholder: i18next.t('weburl'),
					},
					className: s.input,
					value: props.data ? props.data.website_url : '',
					validation: {
						required: false,
						isurl: true,
					},
					valid: true,
					errorMsg: 'invalid_url',
					touched: false,
				},
				textarea: {
					elementType: 'textarea',
					elementConfig: {
						placeholder: i18next.t('info_optional'),
					},
					className: s.textarea,
					value: props.data ? props.data.description : '',
					validation: {
						required: false,
						isinfo: true,
						maxLength: 100,
					},
					valid: true,
					errorMsg: 'invalid_info',
					touched: true,
				},
				city: {
					elementType: 'input',
					elementConfig: {
						type: 'text',
						placeholder: i18next.t('city'),
					},
					className: s.input,
					value: props.data ? props.data.city : '',
					validation: {
						required: true,
						iscities: true,
					},
					valid: props.data && props.data.city ? true : false,
					errorMsg: 'invalid_city_name',
					touched: false,
				},
				postal_code: {
					elementType: 'input',
					elementConfig: {
						type: 'text',
						placeholder: i18next.t('postal_code'),
					},
					className: s.input,
					value: props.data ? props.data.postal_code : '',
					validation: {
						required: true,
						isCanadianPostal: defaultCountry?.isoCode === 'CA' ? true : false,
						isUSPostal: countryConstants.includes(defaultCountry?.isoCode)
							? true
							: false,
						postal:
							defaultCountry?.isoCode === 'CA' ||
								countryConstants.includes(defaultCountry?.isoCode)
								? false
								: true,
					},
					valid: props.data && props.data.postal_code ? true : false,
					errorMsg: 'invalid_postal_code',
					touched: false,
				},
				customer_care_number: {
					elementType: 'input',
					elementConfig: {
						type: 'text',
						placeholder: i18next.t('provider_phone'),
					},
					className: s.input,
					value: custPhoneNumberField ? custPhoneNumberField : '',
					validation: {
						required: true,
						isPhone: true,
					},
					valid: props.data && props.data.customer_care_number ? true : false,
					errorMsg: 'invalid_phone',
					touched: false,
				},
				crm_customer_id: {
					elementType: 'input',
					elementConfig: {
						type: 'text',
						placeholder: i18next.t('customer_id'),
					},
					className: s.input,
					value: props.data ? props.data.crm_customer_id : '',
					validation: {
						required: false,
						isAlphaNumeric: true,
					},
					valid: true,
					errorMsg: 'invalid_customer_id',
					touched: false,
				},
			},
			selectedCountry: defaultCountry ? defaultCountry : null,
			selectedState: defaultState ? defaultState : null,
			statesOfSelectedCountry: [],
			citiesOfSelectedState: [],
			country_code: '',
			countryCode: countryCode,
			PhoneNumberField: PhoneNumberField,
			custPhoneNumberField: custPhoneNumberField,
		};
	}

	componentDidMount() {
		logger('[ManageUsers ProviderEdit] componentDidMount');

		const { data, match, onInit } = this.props;
		data || onInit(match.params.id);
	}

	inputChangedHandler = (event, name) => {
		var value = '';
		if (event && event.target) {
			const target = event.target;
			value = target.value;
		}
		this.setState((prevState) => {
			const state = { ...prevState.controls };
			const control = state[name];
			let formIsValid = true;

			control.value = value;
			control.valid = checkValidity(value, control.validation);

			return {
				controls: state,
				formIsValid: formIsValid,
				formValidMessage: null,
			};
		});
	};

	inputFocusOutHandler = (event, name) => {
		this.setState((prevState) => {
			const state = { ...prevState.controls };
			const controls = state[name];
			controls.touched = true;
			return { controls: state };
		});
	};

	handleCountryChange = (selectedCountry) => {
		this.setState((prevState) => {
			const state = { ...prevState.controls };

			// Clear phone_number field
			state.phone_number.value = '';
			state.phone_number.valid = false;
			state.phone_number.touched = false;

			// Clear city field
			state.city.value = '';
			state.city.valid = false;
			state.city.touched = false;

			// Clear postal_code field
			state.postal_code.value = '';
			state.postal_code.valid = false;
			state.postal_code.touched = false;
			if (countryConstants.includes(selectedCountry.isoCode)) {
				state.postal_code.validation.isCanadianPostal = false;
				state.postal_code.validation.isUSPostal = true;
				state.postal_code.validation.postal = false;
			} else if (selectedCountry.isoCode === 'CA') {
				state.postal_code.validation.isCanadianPostal = true;
				state.postal_code.validation.isUSPostal = false;
				state.postal_code.validation.postal = false;
			} else {
				state.postal_code.validation.isCanadianPostal = false;
				state.postal_code.validation.isUSPostal = false;
				state.postal_code.validation.postal = true;
			}

			// Clear fax field
			state.fax.value = '';
			state.fax.valid = true;
			state.fax.touched = false;

			state.address_1.value = '';
			state.address_1.valid = false;
			state.address_1.touched = false;

			state.address_2.value = '';
			state.address_2.valid = true;
			state.address_2.touched = false;

			// Clear phone_number field
			state.customer_care_number.value = '';
			state.customer_care_number.valid = false;
			state.customer_care_number.touched = false;

			return {
				controls: state,
				selectedCountry: selectedCountry,
				country_code: selectedCountry.phonecode,
				selectedState: null,
			};
		});
	};

	handleStateChange = (selectedState) => {
		this.setState({ selectedState: selectedState });
	};

	saveHandler = () => {
		const {
			textarea,
			fax,
			address_1,
			address_2,
			email_address,
			website_url,
			postal_code,
			city,
			phone_number,
			customer_care_number,
			crm_customer_id,
		} = this.state.controls;
		const {
			match: {
				params: { id },
			},
			history,
			updateProvider,
		} = this.props;
		const { selectedCountry, selectedState } = this.state;
		const code = this.state.selectedCountry
			? `+${this.state.selectedCountry.phonecode}`
			: [];
		const result = this.state.countryCode === code ? code : code;
		const fullPhoneNumber = `${result}-${phone_number.value}`;
		const customer_fullPhoneNumber = `${result}-${customer_care_number.value}`;

		const data = {
			email_address: email_address?.value || '',
			address_1: address_1?.value || '',
			address_2: address_2.value ? address_2.value : '',
			country: selectedCountry.name,
			state: selectedState.name,
			city: city?.value || '',
			postal_code: postal_code?.value || '',
			fax: fax.value ? fax.value : '',
			phone_number: fullPhoneNumber || '',
			website_url: website_url.value ? website_url.value : '',
			customer_care_number: customer_fullPhoneNumber || '',
			description: textarea.value ? textarea.value : '',
			crm_customer_id: crm_customer_id.value ? crm_customer_id.value : '',
		};
		const success = () => {
			history.push(routers.usersProvider(id));
		};
		const error = (data) => this.setState({ formValidMessage: data });
		updateProvider({ data, id, success, error });
	};

	address1Validation = () => {
		const { address_1 } = this.state.controls;
		const length = address_1?.value?.length || 0;
		const maxLength = address_1.validation.maxLength || 100;
		return (
			<span>
				{length}/
				<span
					className={
						length >= maxLength ? s.red : '' + length > 0 ? s.green : ''
					}
				>
					{maxLength - length}
				</span>
			</span>
		);
	};
	address2Validation = () => {
		const { address_2 } = this.state.controls;
		const length = address_2?.value?.length || 0;
		const maxLength = address_2.validation.maxLength;
		return (
			<span>
				{length}/
				<span
					className={
						length >= maxLength ? s.red : '' + length > 0 ? s.green : ''
					}
				>
					{maxLength - length}
				</span>
			</span>
		);
	};
	infoValidation = () => {
		const { textarea } = this.state.controls;
		const length = textarea?.value?.length || 0;
		const maxLength = textarea.validation.maxLength;
		return (
			<span>
				{length}/
				<span
					className={
						length >= maxLength ? s.red : '' + length > 0 ? s.green : ''
					}
				>
					{maxLength - length}
				</span>
			</span>
		);
	};

	render() {
		const { data, history, openModal } = this.props;
		const {
			controls: {
				textarea,
				phone_number,
				fax,
				address_1,
				address_2,
				city,
				postal_code,
				email_address,
				website_url,
				customer_care_number,
				crm_customer_id,
			},
		} = this.state;
		const { selectedCountry, selectedState, formValidMessage } = this.state;
		const code = selectedCountry ? `+${selectedCountry.phonecode}` : '';
		const result = this.state.countryCode === code ? code : code;
		let validMessage = '';
		if (formValidMessage) {
			validMessage = (
				<ul className={s.errorText}>
					{Object.values(formValidMessage).map((fieldErrors, index) => {
						if (
							typeof fieldErrors === 'object' &&
							fieldErrors !== null &&
							'error' in fieldErrors
						) {
							// If the fieldErrors is an object with an "error" property, render the "error" property
							return <li key={index}>{fieldErrors.error}</li>;
						} else if (Array.isArray(fieldErrors)) {
							// If the fieldErrors is an array, map over it and render each string
							return fieldErrors.map((error, errorIndex) => (
								<li key={`${index}-${errorIndex}`}>{error}</li>
							));
						}
						return null; // Return null for any other type of fieldErrors
					})}
				</ul>
			);
		}
		let editView = <Spinner />;
		const isBlocked = data?.is_blocked === true;
		const statusFontcolor = isBlocked ? '#DF0C0C' : '#6C7DDF';
		const statusText = isBlocked ? 'unblock' : 'block';
		const fillColor = isBlocked ? '#DF0C0C' : '#6C7DDF';
		const pathId = isBlocked ? 'Subtract' : 'Vector';
		const pathData = isBlocked ? 'M9.08952 20.752C10.2457 21.2506 11.4807 21.5 12.7946 21.5C14.1086 21.5 15.3433 21.2505 16.4989 20.7516C17.6546 20.2527 18.6598 19.5757 19.5147 18.7205C20.3695 17.8652 21.0463 16.8596 21.5449 15.7034C22.0436 14.5473 22.2929 13.3122 22.2929 11.9983C22.2929 10.6844 22.0435 9.44959 21.5446 8.29398C21.0457 7.13834 20.3687 6.1331 19.5134 5.27825C18.6582 4.42342 17.6525 3.74667 16.4964 3.248C15.3402 2.74933 14.1052 2.5 12.7913 2.5C11.4773 2.5 10.2426 2.74944 9.08694 3.24833C7.93131 3.74721 6.92607 4.42427 6.07122 5.2795C5.21639 6.13472 4.53964 7.1404 4.04097 8.29655C3.5423 9.45268 3.29297 10.6877 3.29297 12.0017C3.29297 13.3156 3.54241 14.5504 4.04129 15.706C4.54018 16.8616 5.21724 17.8669 6.07247 18.7217C6.92769 19.5765 7.93337 20.2533 9.08952 20.752ZM18.4679 17.675C16.9179 19.225 15.0263 20 12.7929 20C11.8547 20 10.9513 19.8413 10.0825 19.524C9.21383 19.2067 8.41985 18.7474 7.70062 18.1461L9.7843 16.0624L8.73048 15.0086L6.64679 17.0923C6.04551 16.3731 5.58622 15.5791 5.26892 14.7104C4.9516 13.8417 4.79294 12.9382 4.79294 12C4.79294 9.76664 5.56794 7.87498 7.11794 6.32498C8.66794 4.77498 10.5596 3.99998 12.7929 3.99998C13.7334 3.99998 14.639 4.15703 15.5098 4.47113C16.3806 4.78524 17.1725 5.24614 17.8853 5.85383L15.8015 7.93755L16.8554 8.99137L18.9391 6.90765C19.534 7.62688 19.9917 8.42086 20.3122 9.28958C20.6327 10.1583 20.7929 11.0618 20.7929 12C20.7929 14.2333 20.0179 16.125 18.4679 17.675Z'
			: 'M12.7946 21.5C11.4807 21.5 10.2457 21.2506 9.08952 20.752C7.93337 20.2533 6.92769 19.5765 6.07247 18.7217C5.21724 17.8669 4.54018 16.8616 4.04129 15.706C3.54241 14.5504 3.29297 13.3156 3.29297 12.0017C3.29297 10.6877 3.5423 9.45268 4.04097 8.29655C4.53964 7.1404 5.21639 6.13472 6.07122 5.2795C6.92607 4.42427 7.93131 3.74721 9.08694 3.24833C10.2426 2.74944 11.4773 2.5 12.7913 2.5C14.1052 2.5 15.3402 2.74933 16.4964 3.248C17.6525 3.74667 18.6582 4.42342 19.5134 5.27825C20.3687 6.1331 21.0457 7.13834 21.5446 8.29398C22.0435 9.44959 22.2929 10.6844 22.2929 11.9983C22.2929 13.3122 22.0436 14.5473 21.5449 15.7034C21.0463 16.8596 20.3695 17.8652 19.5147 18.7205C18.6598 19.5757 17.6546 20.2527 16.4989 20.7516C15.3433 21.2505 14.1086 21.5 12.7946 21.5ZM12.7929 20C15.0263 20 16.9179 19.225 18.4679 17.675C20.0179 16.125 20.7929 14.2333 20.7929 12C20.7929 11.0618 20.6327 10.1583 20.3122 9.28958C19.9917 8.42086 19.534 7.62688 18.9391 6.90765L7.70062 18.1461C8.41985 18.7474 9.21383 19.2067 10.0825 19.524C10.9513 19.8413 11.8547 20 12.7929 20ZM6.64679 17.0923L17.8853 5.85383C17.1725 5.24614 16.3806 4.78524 15.5098 4.47113C14.639 4.15703 13.7334 3.99998 12.7929 3.99998C10.5596 3.99998 8.66794 4.77498 7.11794 6.32498C5.56794 7.87498 4.79294 9.76664 4.79294 12C4.79294 12.9382 4.9516 13.8417 5.26892 14.7104C5.58622 15.5791 6.04551 16.3731 6.64679 17.0923Z';

		if (data) {
			editView = (
				<Fragment>
					<div className={s.providerEditContent}>
						<div className={s.spaceField}>
							<div className={s.editLabel}>{i18next.t('name')}:</div>
							<div className={s.editValue}>{data ? data.name : ' '}</div>
						</div>
						<div className={s.rowEdit}>
							<div className={s.colEdit}>
								<div className={s.editLabel}>
									{i18next.t('provider_email')}{' '}
									<span className={s.mandatory}>{'*'}</span>
								</div>
								<Input
									className={email_address.className}
									elementType={email_address.elementType}
									elementConfig={email_address.elementConfig}
									value={email_address.value}
									invalid={!email_address.valid}
									invalidMsg={i18next.t(email_address.errorMsg)}
									touched={email_address.touched}
									changed={(event) =>
										this.inputChangedHandler(event, 'email_address')
									}
									onBlur={(event) =>
										this.inputFocusOutHandler(event, 'email_address')
									}
									newDesign={true}
								/>
							</div>
							<div className={s.colEdit}>
								<div className={s.editLabel}>{i18next.t('customer_id')} </div>
								<Input
									className={crm_customer_id.className}
									elementType={crm_customer_id.elementType}
									elementConfig={crm_customer_id.elementConfig}
									value={crm_customer_id.value}
									invalid={!crm_customer_id.valid}
									invalidMsg={i18next.t(crm_customer_id.errorMsg)}
									touched={crm_customer_id.touched}
									changed={(event) =>
										this.inputChangedHandler(event, 'crm_customer_id')
									}
									onBlur={(event) =>
										this.inputFocusOutHandler(event, 'crm_customer_id')
									}
									newDesign={true}
								/>
							</div>
						</div>

						<div className={s.rowEdit}>
							<div className={s.colEdit}>
								<div className={s.spaceField}></div>
								<div className={s.editLabel}>
									{i18next.t('country')}{' '}
									<span className={s.mandatory}>{'*'}</span>
								</div>
								<Select
									components={{ DropdownIndicator }}
									placeholder={i18next.t('selectCountry')}
									options={Country.getAllCountries()}
									getOptionLabel={(options) => {
										return options['name'];
									}}
									getOptionValue={(options) => {
										return options['name'];
									}}
									value={this.state.selectedCountry}
									onChange={this.handleCountryChange}
									className={s.dropdown}
									styles={customStyles}
								/>
							</div>

							<div className={s.colEdit}>
								<div className={s.spaceField}></div>
								<div className={s.editLabel}>
									{i18next.t('state')}{' '}
									<span className={s.mandatory}>{'*'}</span>
								</div>
								<Select
									components={{ DropdownIndicator }}
									placeholder={i18next.t('selectState')}
									options={State?.getStatesOfCountry(selectedCountry?.isoCode)}
									getOptionLabel={(options) => options['name']}
									getOptionValue={(options) => options['name']}
									value={selectedState}
									onChange={this.handleStateChange}
									className={s.dropdown}
									newDesign={true}
									styles={customStyles}
								/>
							</div>
						</div>
						<div className={s.rowEdit}>
							<div className={s.colEdit}>
								<div className={s.spaceField}></div>
								<div className={s.editLabel}>
									{i18next.t('city')} <span className={s.mandatory}>{'*'}</span>
								</div>
								<Input
									className={city.className}
									elementType={city.elementType}
									elementConfig={city.elementConfig}
									value={city.value}
									invalid={!city.valid}
									invalidMsg={i18next.t(city.errorMsg)}
									touched={city.touched}
									changed={(event) => this.inputChangedHandler(event, 'city')}
									onBlur={(event) => this.inputFocusOutHandler(event, 'city')}
									newDesign={true}
								/>
							</div>

							<div className={s.colEdit}>
								<div className={s.spaceField}></div>
								<div className={s.editLabel}>
									{i18next.t('postal_code')}{' '}
									<span className={s.mandatory}>{'*'}</span>
								</div>
								<Input
									className={postal_code.className}
									elementType={postal_code.elementType}
									elementConfig={postal_code.elementConfig}
									value={postal_code.value}
									invalid={!postal_code.valid}
									invalidMsg={i18next.t(postal_code.errorMsg)}
									touched={postal_code.touched}
									changed={(event) =>
										this.inputChangedHandler(event, 'postal_code')
									}
									onBlur={(event) =>
										this.inputFocusOutHandler(event, 'postal_code')
									}
									newDesign={true}
								/>
							</div>
						</div>

						<div className={s.rowEditAddress}>
							<div className={s.col1}>
								<div className={s.spaceField}></div>
								<div className={s.editLabel}>
									{i18next.t('provider_address')}{' '}
									<span className={s.mandatory}>{'*'}</span>
								</div>
								<Input
									className={address_1.className}
									elementType={address_1.elementType}
									elementConfig={address_1.elementConfig}
									value={address_1.value}
									invalid={!address_1.valid}
									invalidMsg={i18next.t(address_1.errorMsg)}
									maxlength={address_1.validation.maxLength}
									touched={address_1.touched}
									changed={(event) =>
										this.inputChangedHandler(event, 'address_1')
									}
									onBlur={(event) =>
										this.inputFocusOutHandler(event, 'address_1')
									}
									newDesign={true}
								/>
								<div className={s.alignCountValidation}>
									{this.address1Validation()}
								</div>

								<div className={s.gapAddess}></div>
								<Input
									className={address_2.className}
									elementType={address_2.elementType}
									elementConfig={address_2.elementConfig}
									value={address_2.value ? address_2.value : ''}
									invalid={!address_2.valid}
									invalidMsg={i18next.t(address_2.errorMsg)}
									maxlength={address_2.validation.maxLength}
									touched={address_2.touched}
									changed={(event) =>
										this.inputChangedHandler(event, 'address_2')
									}
									onBlur={(event) =>
										this.inputFocusOutHandler(event, 'address_2')
									}
									newDesign={true}
								/>
								<div className={s.alignCountValidation}>
									{this.address2Validation()}
								</div>
							</div>
						</div>
						<div className={s.rowEdit}>
							<div className={s.colEdit}>
								<div className={s.spaceField}></div>
								<div className={s.editLabel}>
									{i18next.t('provider_phone')}{' '}
									<span className={s.mandatory}>{'*'}</span>
								</div>
								<ul className={s.phoneField}>
									<li>
										<div className={s.inputBlock}>{result}</div>
									</li>
									<li>
										<Input
											className={phone_number.className}
											elementType={phone_number.elementType}
											elementConfig={phone_number.elementConfig}
											value={phone_number.value}
											invalid={!phone_number.valid}
											invalidMsg={i18next.t(phone_number.errorMsg)}
											touched={phone_number.touched}
											changed={(event) =>
												this.inputChangedHandler(event, 'phone_number')
											}
											onBlur={(event) =>
												this.inputFocusOutHandler(event, 'phone_number')
											}
											newDesign={true}
										/>
									</li>
								</ul>
							</div>
							<div className={s.colEdit}>
								<div className={s.spaceField}></div>
								<div className={s.editLabel}>
									{i18next.t('customer_care_number')}{' '}
									<span className={s.mandatory}>{'*'}</span>
								</div>
								<ul className={s.phoneField}>
									<li>
										<div className={s.inputBlock}>{result}</div>
									</li>
									<li>
										<Input
											className={customer_care_number.className}
											elementType={customer_care_number.elementType}
											elementConfig={customer_care_number.elementConfig}
											value={customer_care_number.value}
											invalid={!customer_care_number.valid}
											invalidMsg={i18next.t(customer_care_number.errorMsg)}
											touched={customer_care_number.touched}
											changed={(event) =>
												this.inputChangedHandler(event, 'customer_care_number')
											}
											onBlur={(event) =>
												this.inputFocusOutHandler(event, 'customer_care_number')
											}
											newDesign={true}
										/>
									</li>
								</ul>
							</div>
						</div>

						<div className={s.rowEdit}>
							<div className={s.colEdit}>
								<div className={s.spaceField}></div>
								<div className={s.editLabel}>{i18next.t('provider_fax')}</div>
								<Input
									className={fax.className}
									elementType={fax.elementType}
									elementConfig={fax.elementConfig}
									value={fax.value ? fax.value : ''}
									invalid={!fax.valid}
									invalidMsg={i18next.t(fax.errorMsg)}
									touched={fax.touched}
									changed={(event) => this.inputChangedHandler(event, 'fax')}
									onBlur={(event) => this.inputFocusOutHandler(event, 'fax')}
									newDesign={true}
								/>
							</div>
							<div className={s.colEdit}>
								<div className={s.spaceField}></div>
								<div className={s.editLabel}>{i18next.t('weburl')}</div>
								<Input
									className={website_url.className}
									elementType={website_url.elementType}
									elementConfig={website_url.elementConfig}
									value={website_url.value ? website_url.value : ''}
									invalid={!website_url.valid}
									invalidMsg={i18next.t(website_url.errorMsg)}
									touched={website_url.touched}
									changed={(event) =>
										this.inputChangedHandler(event, 'website_url')
									}
									onBlur={(event) =>
										this.inputFocusOutHandler(event, 'website_url')
									}
									newDesign={true}
								/>
							</div>
						</div>
						<div className={s.rowEdit}>
							<div className={s.editField}>
								<div className={s.editLabel}>{i18next.t('info_optional')}</div>
								<Input
									className={textarea.className}
									elementType={textarea.elementType}
									elementConfig={textarea.elementConfig}
									rows={1}
									maxlength={textarea.validation.maxLength}
									value={textarea?.value || ''}
									changed={(event) =>
										this.inputChangedHandler(event, 'textarea')
									}
									onBlur={(event) =>
										this.inputFocusOutHandler(event, 'textarea')
									}
									newDesign={true}
								/>
								<div className={s.alignCountValidation}>
									{this.infoValidation()}
								</div>
							</div>
						</div>
						{validMessage}
						<div className={s.editButtons}>
							<Button
								className={s.editCancelBtn}
								btnType={'type-22'}
								clicked={() =>
									history.push(
										routers.usersProvider(this.props.match.params.id),
									)
								}
							>
								{i18next.t('cancel')}
							</Button>
							<Button
								className={s.editSaveBtn}
								btnType={'type-21'}
								disabled={
									!this.state.controls.email_address.valid ||
									!this.state.controls.address_1.valid ||
									!this.state.controls.address_2.valid ||
									!this.state.controls.city.valid ||
									!this.state.controls.phone_number.valid ||
									!this.state.controls.customer_care_number.valid ||
									!this.state.controls.postal_code.valid ||
									!this.state.controls.fax.valid ||
									!this.state.controls.website_url.valid ||
									!this.state.controls.textarea.valid ||
									!selectedCountry ||
									!selectedState
								}
								clicked={this.saveHandler}
							>
								{i18next.t('save')}
							</Button>
						</div>
					</div>
				</Fragment>
			);
		}

		return (
			<Fragment>
				<div className={s.providerEditWrapper}>
					<div className={s.providerTitle}>
						<div className={s.displayFlex}>
							<div
								className={s.backBtn}
								onClick={() => {
									history.push(
										routers.usersProvider(this.props.match.params.id),
									);
								}}
							>
								<span>
									<Back width='40' fill='#000' height='40' />
								</span>
							</div>
							<span className={[s.pageTitle, s.providerTitle].join(' ')}>
								{i18next.t('edit_provider')}
							</span>
						</div>
						<div >
						{data?.is_inogen === true ? (
									''
								) : (
							<div
								className={s.blockButtonDiv}
								role='button'
								tabIndex={0}
								onClick={() =>
									openModal({
										type: 'BlockProvider',
										data: data,
										restrictClosing: true
									})
								}
							>
								<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
									<g id="Icon/Block">
										<path id={pathId} fillRule="evenodd" clipRule="evenodd" d={pathData} fill={fillColor} />
									</g>
								</svg>
								<span style={{ marginLeft: '5px', color: statusFontcolor }}>
									{i18next.t(statusText)}
								</span>

							</div>)}
						</div>
						{/* <div className={s.displayFlex}>
							<Button
								className={s.editButton}
								btnType="type-23"
								clicked={() =>
									this.props.openModal({
										type: "DeleteProvider",
										data,
										restrictClosing: true,
									})
								}
							>
								<TrashBin fill={"#6c7ddf"} />
								{i18next.t("delete")}
							</Button>
						</div> */}
					</div>
					{editView}
				</div>
			</Fragment>
		);
	}
}

ProviderEdit.defaultProps = {
	data: {},
};

ProviderEdit.propTypes = {
	data: PropTypes.object,
};

export default ProviderEdit;
