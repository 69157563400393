import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import DatePicker, { registerLocale } from 'react-datepicker';
import { Input, Select } from 'components';
import enUS from 'date-fns/locale/en-US';
import fr from 'date-fns/locale/fr';
import s from '../style.module.scss';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import { isToday } from 'date-fns';
import { DeviceFirmwaresConfig } from '../../../Configs/constants';

registerLocale('us', enUS);
registerLocale('fr', fr);
const FirmwareInfo = ({
	models,
	selectedModel,
	releasedDate,
	partNum,
	version: versionProp,
	onChange,
	onSelectDate,
	// statusId,
	t,
}) => {
	const [startDate, setStartDate] = useState(
		releasedDate ? new Date(releasedDate) : new Date(),
	);
	const [hardwareVersion, setHardwareVersion] = useState('');

	const [hardwareVersionlist, setHardwareVersionList] = useState([]);
	const [version, setVersion] = useState(versionProp);

	const dateFormat = isToday(startDate) ? `'Today', d MMM yyyy` : 'd MMM yyyy';

	const modelsConfig = {
		displayKey: 'description', // show description in model name
		label: {
			path: 'description', // show description in model name
		},
		placeholder: t('select_model'),
	};
	const versionConfig = {
		displayKey: 'description', // show description in model name
		label: {
			path: 'description', // show description in model name
		},
		placeholder: t('select_version'),
	};
	const onDateChange = (date) => {
		onSelectDate(date);
		setStartDate(date);
	};
	const hardwareVersionHandler = (data) => {
		setHardwareVersionList([]);
		setHardwareVersion('');
		setVersion('');
		const deviceVersion = DeviceFirmwaresConfig.find(
			(device) => device.model_id === data.id,
		);
		if (deviceVersion) {
			const transformedHardwareVersionList =
				deviceVersion.hardwareVersionDropdownValues.map((value, index) => ({
					id: String(index + 1),
					description: String(value) + '.',
				}));
			setHardwareVersionList(transformedHardwareVersionList);
		}
	};
	const resetHardwareVersion = (data) => {
		setHardwareVersion('');
		setVersion('');
		setHardwareVersion(data.description);
	};

	return (
		<Fragment>
			<div className={s.row}>
				<div className={s.col}>
					<div className={s.editLabel}>
						{t('product')} <span className={s.mandatory}> {'*'}</span>
					</div>
					<Select
						checked={selectedModel && { [selectedModel.id]: selectedModel }}
						className={s.input}
						data={models}
						config={modelsConfig}
						onItemClick={(data) => {
							onChange(null, 'concentrator_model', data);
							hardwareVersionHandler(data);
						}}
						newDesign={true}
						hardwareVersionChange={true}
					/>
				</div>
			</div>
			<div className={s.row}>
				<div className={s.col}>
					<div className={s.editLabel}>
						{t('hardware_version')} <span className={s.mandatory}> {'*'}</span>
					</div>
					<Select
						key={hardwareVersionlist.length}
						className={s.input}
						data={hardwareVersionlist}
						config={versionConfig}
						onItemClick={(data) => {
							onChange(null, 'hardware_version', data);
							resetHardwareVersion(data);
						}}
						newDesign={true}
					/>
				</div>
				<div className={s.col}>
					<div className={s.editLabel}>
						{t('software_version')} <span className={s.mandatory}> {'*'}</span>
					</div>
					<Input
						value={version || hardwareVersion || ''}
						className={s.input}
						// elementType="mask"
						elementConfig={{
							type: 'text',
							placeholder: t('version') /*, guide: false*/,
						}}
						mask={[
							/[0-9]/,
							/[0-9,.]/,
							/[0-9,.]/,
							/[0-9,.]/,
							/[0-9,.]/,
							/[0-9,.]/,
							/[0-9]/,
						]}
						changed={(e) => {
							const inputValue = e.target.value;
							if (inputValue.startsWith(hardwareVersion)) {
								setVersion(inputValue); // update the version state when the input changes
								onChange(e, 'version');
							}
						}}
						newDesign={true}
					/>
				</div>
			</div>

			<div className={s.row}>
				<div className={s.col}>
					<div className={s.editLabel}>
						{t('release_date')} <span className={s.mandatory}> {'*'}</span>
					</div>
					<DatePicker
						className={s.dateInput}
						selected={startDate}
						minDate={new Date()}
						calendarClassName={s.datepicker}
						onChange={onDateChange}
						disabledKeyboardNavigation
						locale={i18next.language}
						dateFormat={dateFormat}
					/>
				</div>
				<div className={s.col}>
					<div className={s.editLabel}>
						{t('part_number')}{' '}
						{/* {statusId === 2 || statusId === 4 ? (
							<span className={s.mandatory}> {'*'}</span>
						) : null} */}
					</div>
					<Input
						value={partNum || ''}
						className={s.input}
						// elementType="mask"
						elementConfig={{
							type: 'text',
							placeholder: t('part_number') /*, guide: false*/,
						}}
						mask={[
							/[0-9]/,
							/[0-9,-]/,
							/[0-9,-]/,
							/[0-9,-]/,
							/[0-9,-]/,
							/[0-9,-]/,
							/[0-9,-]/,
							/[0-9]/,
						]}
						changed={(e) => onChange(e, 'part_num')}
						newDesign={true}
					/>
				</div>
			</div>
		</Fragment>
	);
};

FirmwareInfo.defaultProps = {
	onChange: () => {},
};

FirmwareInfo.propTypes = {
	onChange: PropTypes.func,
};

export default withTranslation()(FirmwareInfo);
