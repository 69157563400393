import React, { Component } from 'react';
import s from './style.module.scss';
import Button from '../../../components/UI/Button';
import moment from 'moment';
import i18next from 'i18next';
import * as actions from 'store/actions';
import { permissions } from 'helpers';
import { connect } from 'react-redux';
import { makeGetPatientDataState } from 'store/selectors';
import { withTranslation } from 'react-i18next';
import { DataList } from 'components';

moment.updateLocale('en', {
	relativeTime: {
		future: '%s',
		past: `%s ${i18next.t('ago')}`,
		s: `${i18next.t('a_few_seconds')}`,
		ss: `%d ${i18next.t('seconds')}`,
		m: `${i18next.t('a_minute')}`,
		mm: ` %d ${i18next.t('minutes')}`,
		h: ` ${i18next.t('an_hour')}`,
		hh: ` %d ${i18next.t('hours')}`,
		d: `${i18next.t('a_day')}`,
		dd: ` %d ${i18next.t('days')}`,
		M: ` ${i18next.t('a_month')}`,
		MM: ` %d ${i18next.t('months')}`,
		y: ` ${i18next.t('a_year')}`,
		yy: ` %d ${i18next.t('years')}`,
	},
});

class PatientDeviceDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
			groupsData: null,
			isSendingReminder: false,
		};
	}
	componentDidUpdate(prevProps) {
		if (
			this.props.isAllowSendNotification !== prevProps.isAllowSendNotification
		) {
			this.setState({ isSendingReminder: false });
		}
	}
	handleClick = () => {
		this.setState({ isSendingReminder: true });
		this.props.sendUpdateNotification({
			SN: this.props.groupsData?.serial_number || '',
			type: '',
			concentratorProfileId: this.props.profile_id || '',
		});
	};
	render() {
		const { healthCheckData, groupsData, isAllowUpdate, lastLocation } =
			this.props;
		if (groupsData && Object.keys(groupsData).length !== 0) {
			groupsData['last_location'] = lastLocation;
		}
		let notificationButton =
			permissions.check('notifications.firmware_update') && isAllowUpdate ? (
				<div className={s.buttonDetailsPadding}>
					<Button
						className={s.detailsButton}
						clicked={this.handleClick}
						disabled={
							this.state.isSendingReminder || !groupsData?.serial_number
						}
					>
						<span>{i18next.t('send_reminder')}</span>
					</Button>
				</div>
			) : (
				<></>
			);
		return (
			<div>
				<div className={s.concentratorDetailsTopbar}>
					<div className={s.concentratorDetailsTitlebar}>
						<div className={s.concentratorsDetailsFilter}>
							<div className={s.concentratorDetailsHeader}>
								{i18next.t('concentrator_details')}
							</div>
						</div>
					</div>
				</div>
				<DataList
					dataList={groupsData ? [groupsData] : ''}
					title={i18next.t('device_specification')}
					notificationButton={notificationButton}
					isAllowUpdate={isAllowUpdate}
				/>
				<DataList
					dataList={healthCheckData}
					title={i18next.t('device_health')}
				/>
			</div>
		);
	}
}
const mapStateToProps = () => {
	const getPatientDataState = makeGetPatientDataState();
	return (props, state) => getPatientDataState(props, state);
};
const mapDispatchToProps = (dispatch) => {
	return {
		getProfiles: (options, isMerge) =>
			dispatch(actions.getProfiles(options, isMerge)),
		sendUpdateNotification: (args, data) =>
			dispatch(actions.sendUpdateNotification(args, data)),
	};
};
export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(withTranslation()(PatientDeviceDetails));
