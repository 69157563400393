import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { SearchMultiSelect } from 'components';

import s from './style.module.scss';
import i18next from 'i18next';

const SelectConcentrators = (props) => {
	const { status, selected, onSubmit, model } = props;
	if (status !== 4) return null;

	return (
		<Fragment>
			<div className={s.selectorContainer}>
				<div className={s.editLabel}>
					{i18next.t('select_concentrators')}{' '}
					{status === 4 ? <span className={s.mandatory}> {'*'}</span> : null}
				</div>

				<SearchMultiSelect {...props} />

				<ul className={s.checkedList}>
					{selected?.length > 0 &&
						selected.map(
							(el, index) =>
								model &&
								el?.concentrator_model_id === model?.id && (
									<li key={el.id}>
										{el.serial_number}
										<i
											className={s.remove}
											onClick={(e) => onSubmit(e, index)}
										/>
									</li>
								),
						)}
				</ul>
			</div>
		</Fragment>
	);
};

SelectConcentrators.defaultProps = {
	selected: [],
	onRemove: () => {},
};

SelectConcentrators.propTypes = {
	selected: PropTypes.array,
	onRemove: PropTypes.func,
};

export default SelectConcentrators;
