import React, { PureComponent } from 'react';
import onClickOutside from 'react-onclickoutside';
import PropTypes from 'prop-types';
import { AuxWrap } from 'hoc';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';

import sOld from './style.module.scss';
import sLatest from './newStyle.module.scss';
import AutoComplete from './AutoComplete/index';

class Select extends PureComponent {
	state = {
		isOpen: false,
		labelName: '',
	};
	componentDidMount() {
		// Set the initial labelName when the component mounts
		this.AssignLabelName();
	}

	componentDidUpdate(prevProps) {
		// Recalculate labelName if the data prop changes
		if (prevProps.data !== this.props.data) {
			this.AssignLabelName();
		}
	}

	AssignLabelName = () => {
		const { data } = this.props;

		// Set a default label name
		let labelName = i18next.t('state');

		if (data && data.length > 0) {
			// Check the properties of the first item in the data array
			const el = data[0];
			if (el.label) {
				labelName = i18next.t('selectUserRole');
			} else if (el.name) {
				labelName = i18next.t('select_provider');
			}
		}

		// Update the state with the calculated labelName
		this.setState({ labelName });
	};

	getCurrentValues = () => {
		const { t } = this.props;
		const s = this.props.newDesign ? sLatest : sOld;
		const checkedArray = Object.values(this.props.checked);
		const values = checkedArray.map((obj) => obj[this.props.config.displayKey]);

		return (
			values.join(', ') ||
			t(this.props.config.defaultValue) || (
				<span className={s.placeholder}>
					{t(this.props.config.placeholder)}
				</span>
			) ||
			this.props.data[0][this.props.config.displayKey]
		);
	};

	getDefaultValue = () => {
		const { t } = this.props;
		if (this.props.config.defaultValue) {
			const isNotSet = !Object.values(this.props.checked).length;
			const s = this.props.newDesign ? sLatest : sOld;
			return (
				<AuxWrap>
					<li
						className={isNotSet ? s.active : ''}
						onClick={this.props.onDefaultClick}
					>
						{t(this.props.config.defaultValue)}
					</li>
					<li className={s.separator}></li>
				</AuxWrap>
			);
		}
	};
	handleClickOutside = () => {};
	getItemstList = () => {
		const { t, data } = this.props;
		return data.map((el) => t(el[this.props.config.label.path]));
	};

	handleClick = (value) => {
		// 👇️ take parameter passed from Child component
		if (value === '') {
			const disabledBtn = false;
			this.props.onItemClick(disabledBtn);
			this.setState({ isOpen: false });
		}
		this.props.data &&
			this.props.data.length > 0 &&
			this.props.data.map((el) => {
				if (
					el.name === value ||
					el.label === value ||
					(el.description && el.description === value)
				) {
					this.props.onItemClick(el, this.props.type);
					this.setState({ isOpen: false });
				}
				return '';
			});
	};
	render() {
		const s = this.props.newDesign ? sLatest : sOld;
		const classes = [s.wrapper, s[this.props.theme], this.props.className];

		if (this.state.isOpen) {
			classes.push(s.open);
		}

		return (
			<div className={classes.join(' ')}>
				<AutoComplete
					newDesign={this.props.newDesign}
					hardwareVersionChange={this.props.hardwareVersionChange}
					isDisabled={this.props.isDisabled}
					data={this.getItemstList()}
					label={this.props?.config?.placeholder || this.state.labelName}
					onSubmit={this.handleClick}
					value={i18next.t(this.props.defaultValue)}
				/>
			</div>
		);
	}
}

Select.defaultProps = {
	// config: {},
	data: [],
	checked: {},
	type: 'select',
	onItemClick: () => {},
};

Select.propTypes = {
	// config: PropTypes.object,
	data: PropTypes.array,
	checked: PropTypes.object,
	type: PropTypes.string,
	onItemClick: PropTypes.func,
};

export default withTranslation()(onClickOutside(Select));
