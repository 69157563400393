export const LOADING_ON = 'LOADING_ON';
export const LOADING_OFF = 'LOADING_OFF';

export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

export const GET_MODELS_START = 'GET_MODELS_START';
export const GET_MODELS_SUCCESS = 'GET_MODELS_SUCCESS';
export const CLEAR_MODELS = 'CLEAR_MODELS';

export const GET_PROVIDERS_NOTIFIABLE_START = 'GET_PROVIDERS_NOTIFIABLE_START';
export const GET_PROVIDERS_NOTIFIABLE_SUCCESS =
	'GET_PROVIDERS_NOTIFIABLE_SUCCESS';

export const GET_FIRMWARES_NOTIFIABLE_START = 'GET_FIRMWARES_NOTIFIABLE_START';
export const GET_FIRMWARES_NOTIFIABLE_SUCCESS =
	'GET_FIRMWARES_NOTIFIABLE_SUCCESS';

export const GET_ERRORS_START = 'GET_ERRORS_START';
export const GET_ERRORS_SUCCESS = 'GET_ERRORS_SUCCESS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';

export const GET_CURRENT_USER_START = 'GET_CURRENT_USER_START';
export const GET_CURRENT_USER_SUCCESS = 'GET_CURRENT_USER_SUCCESS';
export const GET_CURRENT_USER_FAIL = 'GET_CURRENT_USER_FAIL';
export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE';

export const GET_CITIES_START = 'GET_CITIES_START';
export const GET_CITIES_SUCCESS = 'GET_CITIES_SUCCESS';
export const CLEAR_CITIES = 'CLEAR_CITIES';

export const GET_STATES_START = 'GET_STATES_START';
export const GET_STATES_SUCCESS = 'GET_STATES_SUCCESS';
export const CLEAR_STATES = 'CLEAR_STATES';

export const GET_TIMEZONES_START = 'GET_TIMEZONES_START';
export const GET_TIMEZONES_SUCCESS = 'GET_TIMEZONES_SUCCESS';

export const GET_LOCATIONS_START = 'GET_LOCATIONS_START';
export const GET_LOCATIONS_SUCCESS = 'GET_LOCATIONS_SUCCESS';
export const GET_LOCATIONS_COUNT = 'GET_LOCATIONS_COUNT';

export const GET_PROFILES_START = 'GET_PROFILES_START';
export const GET_PROFILES_SUCCESS = 'GET_PROFILES_SUCCESS';
export const GET_PROFILES_BY_ID_START = 'GET_PROFILES_BY_ID_START';
export const GET_PROFILES_BY_ID_SUCCESS = 'GET_PROFILES_BY_ID_SUCCESS';
export const GET_SEARCH_DATA_START = 'GET_SEARCH_DATA_START';
export const GET_SEARCH_DATA_SUCCESS = 'GET_SEARCH_DATA_SUCCESS';
export const CLEAR_SEARCH_DATA = 'CLEAR_SEARCH_DATA';
export const SET_SEARCH_DATA_TO_STORE = 'SET_SEARCH_DATA_TO_STORE';
export const SET_FILTER = 'SET_FILTER';
export const REMOVE_FILTER = 'REMOVE_FILTER';
export const SET_FILTER_START = 'SET_FILTER_START';
export const SET_FILTER_SUCCESS = 'SET_FILTER_SUCCESS';
export const REMOVE_FILTER_START = 'REMOVE_FILTER_START';
export const REMOVE_FILTER_SUCCESS = 'REMOVE_FILTER_SUCCESS';
export const SET_SEARCH_PARAM = 'SET_SEARCH_PARAM';
export const GET_HEALTHCHECK_START = 'GET_HEALTHCHECK_START';
export const GET_HEALTHCHECK_SUCCESS = 'GET_HEALTHCHECK_SUCCESS';
export const CLEAR_CONCENTRATORS = 'CLEAR_CONCENTRATORS';

export const GET_LOCATION_START = 'GET_LOCATION_START';
export const GET_LOCATION_SUCCESS = 'GET_LOCATION_SUCCESS';
export const GET_LOCATION_COUNT_SUCCESS = 'GET_LOCATION_COUNT_SUCCESS';

export const GET_PROVIDERS_START = 'GET_PROVIDERS_START';
export const GET_PROVIDERS_SUCCESS = 'GET_PROVIDERS_SUCCESS';
export const ADD_PROVIDER = 'ADD_PROVIDER';
export const DELETE_PROVIDER = 'DELETE_PROVIDER';
export const UPDATE_PROVIDER = 'UPDATE_PROVIDER';
export const CLEAR_PROVIDERS = 'CLEAR_PROVIDERS';
export const BLOCK_PROVIDER = 'BLOCK_PROVIDER';

export const GET_PROFILES_REPORT_START = 'GET_PROFILES_REPORT_START';
export const GET_PROFILES_REPORT_SUCCESS = 'GET_PROFILES_REPORT_SUCCESS';
export const GET_FIRMWARES_START = 'GET_FIRMWARES_START';
export const GET_FIRMWARES_SUCCESS = 'GET_FIRMWARES_SUCCESS';
export const SET_REQUEST_PARAM = 'SET_REQUEST_PARAM';
export const SET_REPORT_FILTER = 'SET_REPORT_FILTER';
export const REMOVE_REPORT_FILTER = 'REMOVE_REPORT_FILTER';
export const CLEAR_REPORTS = 'CLEAR_REPORTS';

export const GET_PUSH_NOTIFICATIONS_HISTORY_START =
	'GET_PUSH_NOTIFICATIONS_HISTORY_START';
export const GET_PUSH_NOTIFICATIONS_HISTORY_SUCCESS =
	'GET_PUSH_NOTIFICATIONS_HISTORY_SUCCESS';
export const GET_PUSH_NOTIFICATIONS_TYPES_START =
	'GET_PUSH_NOTIFICATIONS_TYPES_START';
export const GET_PUSH_NOTIFICATIONS_TYPES_SUCCESS =
	'GET_PUSH_NOTIFICATIONS_TYPES_SUCCESS';
export const GET_PUSH_NOTIFICATIONS_FIRMWARES_START =
	'GET_PUSH_NOTIFICATIONS_FIRMWARES_START';
export const GET_PUSH_NOTIFICATIONS_FIRMWARES_SUCCESS =
	'GET_PUSH_NOTIFICATIONS_FIRMWARES_SUCCESS';
export const SET_PUSH_NOTIFICATIONS_REQUEST_PARAM =
	'SET_PUSH_NOTIFICATIONS_REQUEST_PARAM';
export const CLEAR_PUSH_NOTIFICATIONS = 'CLEAR_PUSH_NOTIFICATIONS';
export const ADD_PUSH_NOTIFICATIONS_HISTORY = 'ADD_PUSH_NOTIFICATIONS_HISTORY';

export const GET_USERS_PENDING_START = 'GET_USERS_PENDING_START';
export const GET_USERS_PENDING_SUCCESS = 'GET_USERS_PENDING_SUCCESS';
export const CHANGE_USERS_PENDING_STATUS_START =
	'CHANGE_USERS_PENDING_STATUS_START';
export const CHANGE_USERS_PENDING_STATUS_SUCCESS =
	'CHANGE_USERS_PENDING_STATUS_SUCCESS';
export const APPROVE_USERS_PENDING_START = 'APPROVE_USERS_PENDING_START';
export const APPROVE_USERS_PENDING_SUCCESS = 'APPROVE_USERS_PENDING_SUCCESS';
export const CLEAR_USERS_PENDING = 'CLEAR_USERS_PENDING';

export const GET_SEARCH_USERS_START = 'GET_SEARCH_USERS_START';
export const GET_SEARCH_USERS_SUCCESS = 'GET_SEARCH_USERS_SUCCESS';
export const CHANGE_SEARCH_USERS_STATUS_START =
	'CHANGE_SEARCH_USERS_STATUS_START';
export const CHANGE_SEARCH_USERS_STATUS_SUCCESS =
	'CHANGE_SEARCH_USERS_STATUS_SUCCESS';
export const CLEAR_SEARCH_USERS = 'CLEAR_SEARCH_USERS';

export const GET_SEARCH_PROVIDERS_START = 'GET_SEARCH_PROVIDERS_START';
export const GET_SEARCH_PROVIDERS_SUCCESS = 'GET_SEARCH_PROVIDERS_SUCCESS';
export const CLEAR_SEARCH_PROVIDERS = 'CLEAR_SEARCH_PROVIDERS';

export const GET_MANAGE_PROVIDER_START = 'GET_MANAGE_PROVIDER_START';
export const GET_MANAGE_PROVIDER_SUCCESS = 'GET_MANAGE_PROVIDER_SUCCESS';
export const CHANGE_MANAGE_USERS_STATUS_START =
	'CHANGE_MANAGE_USERS_STATUS_START';
export const CHANGE_MANAGE_USERS_STATUS_SUCCESS =
	'CHANGE_MANAGE_USERS_STATUS_SUCCESS';
export const CLEAR_MANAGE_PROVIDER = 'CLEAR_MANAGE_PROVIDER';

export const ADD_USER_START = 'ADD_USER_START';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const CHANGE_USER_PROVIDER_START = 'CHANGE_USER_PROVIDER_START';
export const CHANGE_USER_PROVIDER_SUCCESS = 'CHANGE_USER_PROVIDER_SUCCESS';
export const CHANGE_USER_ROLE_START = 'CHANGE_USER_ROLE_START';
export const CHANGE_USER_ROLE_SUCCESS = 'CHANGE_USER_ROLE_SUCCESS';

export const GET_ALL_FIRMWARES_START = 'GET_ALL_FIRMWARES_START';
export const GET_ALL_FIRMWARES_SUCCESS = 'GET_ALL_FIRMWARES_SUCCESS';
export const CLEAR_FIRMWARES = 'CLEAR_FIRMWARES';

export const GET_FIRMWARE_START = 'GET_FIRMWARE';
export const GET_FIRMWARE_SUCCESS = 'GET_FIRMWARE_SUCCESS';
export const CLEAR_FIRMWARE_BY_ID = 'CLEAR_FIRMWARE_BY_ID';

export const COPY_FIRMWARE = 'COPY_FIRMWARE';

export const SET_ALL_FIRMWARES_REQUEST_PARAM =
	'SET_ALL_FIRMWARES_REQUEST_PARAM';
export const MANAGE_FIRMWARE_START = 'MANAGE_FIRMWARE_START';
export const MANAGE_FIRMWARE_SUCCESS = 'MANAGE_FIRMWARE_SUCCESS';
export const DELETE_FIRMWARE = 'DELETE_FIRMWARE';

export const GET_ROLES_START = 'GET_ROLES_START';
export const GET_ROLES_SUCCESS = 'GET_ROLES_SUCCESS';
export const CLEAN_ROLES = 'CLEAN_ROLES';

export const GET_TERMS_START = 'GET_TERMS_START';
export const GET_TERMS_SUCCESS = 'GET_TERMS_SUCCESS';

export const GET_USER_CONSENT_START = 'GET_USER_CONSENT_START';
export const GET_USER_CONSENT_SUCCESS = 'GET_USER_CONSENT_SUCCESS';
export const GET_PORTAL_CONSENTS_START = 'GET_PORTAL_CONSENTS_START';
export const GET_PORTAL_CONSENTS_SUCCESS = 'GET_PORTAL_CONSENTS_SUCCESS';
export const ADD_USER_CONSENT_START = 'ADD_USER_CONSENT_START';
export const ADD_USER_CONSENT_SUCCESS = 'ADD_USER_CONSENT_SUCCESS';

export const GET_PINCODES_START = 'GET_PINCODES_START';
export const GET_PINCODES_SUCCESS = 'GET_PINCODES_SUCCESS';
export const CLEAR_PINCODES = 'CLEAR_PINCODES';

export const SET_DASH_PARAM = 'SET_DASH_PARAM';
export const GET_RECENT_ERROR_START = 'GET_RECENT_ERROR_START';
export const GET_RECENT_ERROR_SUCCESS = 'GET_RECENT_ERROR_SUCCESS';

export const GET_CURRENTLY_CONNECTED_START = 'GET_CURRENTLY_CONNECTED_START';
export const GET_CURRENTLY_CONNECTED_SUCCESS =
	'GET_CURRENTLY_CONNECTED_SUCCESS';

export const GET_USER_DEVICE_START = 'GET_USER_DEVICE_START';
export const GET_USER_DEVICE_SUCCESS = 'GET_USER_DEVICE_SUCCESS';

export const GET_SOFTWARE_VERSIONS_START = 'GET_SOFTWARE_VERSIONS_START';
export const GET_SOFTWARE_VERSIONS_SUCCESS = 'GET_SOFTWARE_VERSIONS_SUCCESS';

export const GET_COLUMN_LIFE_START = 'GET_COLUMN_LIFE_START';
export const GET_COLUMN_LIFE_SUCCESS = 'GET_COLUMN_LIFE_SUCCESS';

export const GET_APP_CONNECTED_START = 'GET_APP_CONNECTED_START';
export const GET_APP_CONNECTED_SUCCESS = 'GET_APP_CONNECTED_SUCCESS';

export const ENGINEERING_GET_RECENT_ERROR_START =
	'ENGINEERING_GET_RECENT_ERROR_START';
export const ENGINEERING_GET_RECENT_ERROR_SUCCESS =
	'ENGINEERING_GET_RECENT_ERROR_SUCCESS';
export const ENGINEERING_GET_APP_CONNECTED_START =
	'ENGINEERING_GET_APP_CONNECTED_START';
export const ENGINEERING_GET_APP_CONNECTED_SUCCESS =
	'ENGINEERING_GET_APP_CONNECTED_SUCCESS';
export const ENGINEERING_GET_COLUMN_LIFE_START =
	'ENGINEERING_GET_COLUMN_LIFE_START';
export const ENGINEERING_GET_COLUMN_LIFE_SUCCESS =
	'ENGINEERING_GET_COLUMN_LIFE_SUCCESS';
export const ENGINEERING_GET_CURRENTLY_CONNECTED_START =
	'ENGINEERING_GET_CURRENTLY_CONNECTED_START';
export const ENGINEERING_GET_CURRENTLY_CONNECTED_SUCCESS =
	'ENGINEERING_GET_CURRENTLY_CONNECTED_SUCCESS';
export const ENGINEERING_GET_SOFTWARE_VERSIONS_START =
	'ENGINEERING_GET_SOFTWARE_VERSIONS_START';
export const ENGINEERING_GET_SOFTWARE_VERSIONS_SUCCESS =
	'ENGINEERING_GET_SOFTWARE_VERSIONS_SUCCESS';
export const ENGINEERING_SET_DASH_PARAM = 'ENGINEERING_SET_DASH_PARAM';
export const ENGINEERING_RESET_DASH_PARAM = 'ENGINEERING_RESET_DASH_PARAM';
export const GET_O2_PURITY_START = 'GET_O2_PURITY_START';
export const GET_O2_PURITY_SUCCESS = 'GET_O2_PURITY_SUCCESS';
export const GET_SYSTEM_POWER_START = 'GET_SYSTEM_POWER_START';
export const GET_SYSTEM_POWER_SUCCESS = 'GET_SYSTEM_POWER_SUCCESS';
export const GET_LIFE_CLOCK_START = 'GET_LIFE_CLOCK_START';
export const GET_LIFE_CLOCK_SUCCESS = 'GET_LIFE_CLOCK_SUCCESS';
export const GET_FLOW_SETTINGS_START = 'GET_FLOW_SETTINGS_START';
export const GET_FLOW_SETTINGS_SUCCESS = 'GET_FLOW_SETTINGS_SUCCESS';
export const GET_BATTERY_STATUS_START = 'GET_BATTERY_STATUS_START';
export const RECENT_ERROR_STATUS_SUCCESS = 'RECENT_ERROR_STATUS_SUCCESS';
export const GET_BATTERY_STATUS_SUCCESS = 'GET_BATTERY_STATUS_SUCCESS';
export const RECENT_ERROR_STATUS_START = 'RECENT_ERROR_STATUS_START';
export const GET_BATTERY_STATUS_SUCCESS_BY_ID =
	'GET_BATTERY_STATUS_SUCCESS_BY_ID';

export const GET_PATIENTDEMOGRAPHIC_INFO_START =
	'GET_PATIENTDEMOGRAPHIC_INFO_START';
export const GET_PATIENTDEMOGRAPHIC_INFO = 'GET_PATIENTDEMOGRAPHIC_INFO';
export const GET_PATIENTDEMOGRAPHIC_INFO_SUCCESS =
	'GET_PATIENTDEMOGRAPHIC_INFO_SUCCESS';
export const GET_PATIENT_DEVICE_ERRORS_INFO_START =
	'GET_PATIENT_DEVICE_ERRORS_INFO_START';
export const GET_PATIENT_DEVICE_ERRORS_INFO_SUCCESS =
	'GET_PATIENT_DEVICE_ERRORS_INFO_SUCCESS';
export const GET_TROUBLE_SHOOTING_GUIDE_START =
	'GET_TROUBLE_SHOOTING_GUIDE_START';
export const GET_TROUBLE_SHOOTING_GUIDE_SUCCESS =
	'GET_TROUBLE_SHOOTING_GUIDE_SUCCESS';
export const RESET_PATIENT_DATA = 'RESET_PATIENT_DATA';

export const SET_PATIENT_SEARCH_PARAM = 'SET_PATIENT_SEARCH_PARAM';
export const GET_PATIENT_SEARCH_DETAILS_START =
	'GET_PATIENT_SEARCH_DETAILS_START';
export const GET_PATIENT_SEARCH_DETAILS_SUCCESS =
	'GET_PATIENT_SEARCH_DETAILS_SUCCESS';
export const CLEAR_PATIENT_SEARCH_DATA = 'CLEAR_PATIENT_SEARCH_DATA';
export const SET_VALUE = 'SET_VALUE';

export const ERROR_RESOLVING_START = 'ERROR_RESOLVING_START';
export const ERROR_RESOLVING_SUCCESS = 'ERROR_RESOLVING_SUCCESS';

export const GET_UNRESOLVED_ERRORS_START = 'GET_UNRESOLVED_ERRORS_START';
export const GET_UNRESOLVED_ERRORS = 'GET_UNRESOLVED_ERRORS';
export const GET_UNRESOLVED_ERRORS_SUCCESS = 'GET_UNRESOLVED_ERRORS_SUCCESS';

export const GET_ENGINEERINGPARAMS_START = 'GET_ENGINEERINGPARAMS_START';
export const GET_ENGINEERINGPARAMS_SUCCESS = 'GET_ENGINEERINGPARAMS_SUCCESS';

export const GET_ENGINEERINGVALUES_START = 'GET_ENGINEERINGVALUES_START';
export const GET_ENGINEERINGVALUESS_SUCCESS = 'GET_ENGINEERINGVALUESS_SUCCESS';

export const POST_UNRESOLVED_DASHBOARD_ERRORS_START =
	'POST_UNRESOLVED_DASHBOARD_ERRORS_START';
export const POST_UNRESOLVED_DASHBOARD_ERRORS =
	'POST_UNRESOLVED_DASHBOARD_ERRORS';
export const POST_UNRESOLVED_DASHBOARD_ERRORS_SUCCESS =
	'POST_UNRESOLVED_DASHBOARD_ERRORS_SUCCESS';

export const GET_CONCENTRATORPROFILES_START = 'GET_CONCENTRATORPROFILES_START';
export const GET_CONCENTRATORPROFILES_SUCCESS =
	'GET_CONCENTRATORPROFILES_SUCCESS';

export const FETCH_CONCENTRATOR_DATA = 'FETCH_CONCENTRATOR_DATA';
export const SET_CONCENTRATOR_DATA = 'SET_CONCENTRATOR_DATA';
export const ERROR_CONCENTRATOR_DATA = 'ERROR_CONCENTRATOR_DATA';

export const SEND_UPDATE_NOTIFICATION_START = 'SENDING_NOTIFICATION';
export const SEND_UPDATE_NOTIFICATION_SUCCESS = 'SEND_NOTIFICATION_SUCCESS';
export const SEND_UPDATE_NOTIFICATION_ERROR = 'SEND_NOTIFICATION_ERROR';

export const SET_ACTIVE_TAB_INDEX = 'SET_ACTIVE_TAB_INDEX';
export const SET_ACTIVE_TAB_NAME = 'SET_ACTIVE_TAB_NAME';
export const SET_TABLE_DATA = 'SET_TABLE_DATA';
export const SET_LOADING = 'SET_LOADING';
export const FETCH_HISTORY_DATA_SUCCESS = 'FETCH_HISTORY_DATA_SUCCESS';
export const FETCH_HISTORY_DATA_FAILURE = 'FETCH_HISTORY_DATA_FAILURE';

export const SEND_NOTIFICATION_START = 'SEND_NOTIFICATION_START';
export const SEND_NOTIFICATION_SUCCESS = 'SEND_NOTIFICATION_SUCCESS';
export const SEND_NOTIFICATION_FAIL = 'SEND_NOTIFICATION_FAIL';

export const CHECK_UPDATE_SUCCESS = 'CHECK_UPDATE_SUCCESS';

export const FETCH_TRANSLATIONS_REQUEST = 'FETCH_TRANSLATIONS_REQUEST';
export const FETCH_TRANSLATIONS_SUCCESS = 'FETCH_TRANSLATIONS_SUCCESS';
export const FETCH_TRANSLATIONS_FAILURE = 'FETCH_TRANSLATIONS_FAILURE';

export const UPDATE_SCRIPT_LOADED_FLAG = 'UPDATE_SCRIPT_LOADED_FLAG';

export const GET_CONCENTRATORLOCATIONS_BY_PROFILEID_START =
	'GET_CONCENTRATORLOCATIONS_BY_PROFILEID_START';
export const GET_CONCENTRATORLOCATIONS_BY_PROFILEID_SUCCESS =
	'GET_CONCENTRATORLOCATIONS_BY_PROFILEID_SUCCESS';
