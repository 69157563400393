import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
	data: [],
	loading: false,
};

const getProvidersStart = (state) => {
	return updateObject(state, { loading: true });
};

const getProvidersSuccess = (state, action) => {
	action.data.sort((a) => (a.is_inogen ? -1 : 1));
	return updateObject(state, { data: action.data, loading: false });
};

const addProvider = (state, action) => {
	const newData = [...state.data];

	newData.push(action.data);

	return { data: newData };
};

const deleteProvider = (state, action) => {
	const newData = state.data.filter((el) => el.id !== action.id);

	return { data: newData };
};

const blockProvider = (state) => {
	return updateObject(state, { loading: true });
};

const updateProvider = (state, action) => {
	const newData = state.data.map((el) => {
		if (el.id === parseFloat(action.id)) {
			return action.data;
		}

		return el;
	});

	return { data: newData };
};

const clearProviders = () => {
	return initialState;
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_PROVIDERS_START:
			return getProvidersStart(state, action);
		case actionTypes.GET_PROVIDERS_SUCCESS:
			return getProvidersSuccess(state, action);
		case actionTypes.ADD_PROVIDER:
			return addProvider(state, action);
		case actionTypes.DELETE_PROVIDER:
			return deleteProvider(state, action);
		case actionTypes.UPDATE_PROVIDER:
			return updateProvider(state, action);
		case actionTypes.BLOCK_PROVIDER:
			return blockProvider(state, action);
		case actionTypes.CLEAR_PROVIDERS:
			return clearProviders(state, action);

		default:
			return state;
	}
};

export default reducer;
