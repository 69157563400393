import React from 'react';
import { Button } from 'components';
import { AuxWrap } from 'hoc';
import { Close } from 'components/Icons';
import s from './style.module.scss';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';

const BlockProvider = (props) => {
	const { data, approveHandler, cancelHandler } = props;
	console.log(data)
	var is_blocked = data?.is_blocked;
	return (
		<AuxWrap>
			<div className={s.titleAlign}>
				<div className={s.iconTitle}>
					<svg
						width='36'
						height='36'
						viewBox='0 0 36 36'
						fill='#DF0C0C'
						xmlns='http://www.w3.org/2000/svg'
						style={{ marginRight: '8px' }}
					>
						<path d='M3.4508 31.5C3.2258 31.5 3.03015 31.4484 2.86385 31.3453C2.69755 31.2422 2.5682 31.1063 2.4758 30.9375C2.37163 30.7725 2.31434 30.5938 2.30394 30.4013C2.29351 30.2088 2.3508 30.0125 2.4758 29.8125L17.0258 4.6875C17.1508 4.4875 17.2946 4.34375 17.4571 4.25625C17.6196 4.16875 17.8008 4.125 18.0008 4.125C18.2008 4.125 18.3821 4.16875 18.5446 4.25625C18.7071 4.34375 18.8508 4.4875 18.9758 4.6875L33.5258 29.8125C33.6508 30.0125 33.7081 30.2088 33.6977 30.4013C33.6873 30.5938 33.63 30.7725 33.5258 30.9375C33.4334 31.1063 33.3041 31.2422 33.1378 31.3453C32.9715 31.4484 32.7758 31.5 32.5508 31.5H3.4508ZM5.4008 29.25H30.6008L18.0008 7.5L5.4008 29.25ZM18.1574 27.1125C18.478 27.1125 18.7446 27.0041 18.9571 26.7872C19.1696 26.5703 19.2758 26.3015 19.2758 25.9809C19.2758 25.6603 19.1674 25.3938 18.9505 25.1813C18.7336 24.9688 18.4648 24.8625 18.1442 24.8625C17.8236 24.8625 17.5571 24.9709 17.3446 25.1878C17.1321 25.4047 17.0258 25.6735 17.0258 25.9941C17.0258 26.3147 17.1342 26.5813 17.3511 26.7938C17.568 27.0063 17.8368 27.1125 18.1574 27.1125ZM18.1574 22.95C18.478 22.95 18.7446 22.8422 18.9571 22.6266C19.1696 22.4109 19.2758 22.1438 19.2758 21.825V15.675C19.2758 15.3563 19.1674 15.0891 18.9505 14.8734C18.7336 14.6578 18.4648 14.55 18.1442 14.55C17.8236 14.55 17.5571 14.6578 17.3446 14.8734C17.1321 15.0891 17.0258 15.3563 17.0258 15.675V21.825C17.0258 22.1438 17.1342 22.4109 17.3511 22.6266C17.568 22.8422 17.8368 22.95 18.1574 22.95Z' />
					</svg>

					<span className={s.titleTypography}>
						{
							is_blocked === true ? i18next.t('unblock_provider') : i18next.t('block_provider')
						}
					</span>
				</div>
				<Close
					className={s.closeBtn}
					width={36}
					height={36}
					fill='#6D6D6D'
					onClick={cancelHandler}
				/>
			</div>

			<div className={s.contentContainer}>
				<p className={s.stepAlign}>
					{
						is_blocked === true ? i18next.t('are_you_sure_you_would_like_to_unblock') : i18next.t('are_you_sure_you_would_like_to_block')
					}
					<strong>{' '}{data.name}</strong>?
					<br></br>
					<br></br>
					<br></br>
					<b>{i18next.t('warning')}: {' '}</b>
					{
						is_blocked === true ? i18next.t('all_users_linked_to_this_provider_will_be_unblocked_too') : i18next.t('all_users_linked_to_this_provider_will_be_blocked_too')
					}
				</p>
				{/* {data} */}
				<div className={s.buttons}>
					<Button
						btnType='type-10'
						className={s.deleteBtn}
						clicked={approveHandler}
					>
						{
							is_blocked === true ? i18next.t('yes_unblock') : i18next.t('yes_block')
						}
					</Button>

					<Button
						btnType='type-11'
						className={s.cancelBtn}
						clicked={cancelHandler}
					>
						{i18next.t('cancel')}
					</Button>
				</div>
			</div>
		</AuxWrap>
	);
};

export default withTranslation()(BlockProvider);
