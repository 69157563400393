import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { Route, withRouter } from 'react-router-dom';
import { ModalLatest as Modal, Spinner } from 'components';
import Users from './Users';
import SearchResults from './SearchResults';
import Provider from './Provider';
import ProviderEdit from './ProviderEdit';
import { logger, withTracker } from 'helpers';
import { AuxWrap } from 'hoc';
import * as actions from 'store/actions';
import { makeGetAllManageUserState } from 'store/selectors';
import { routers } from 'settings';
import { AddProvider, AddUser, DeleteProvider, UserDetails, BlockProvider } from './Popups';
import {
	getUsersOptions,
	getProvidersParams,
	getProvider,
} from './Configs/paramsCreator';

import s from './style.module.scss';
import { withTranslation } from 'react-i18next';

class UsersProviders extends Component {
	constructor(props) {
		super(props);

		withTracker({
			page: props.location.pathname,
			category: 'Providers and Users',
			action: 'Page view',
		});

		this.state = {
			searchValue: '',
			modal: {
				isOpen: false,
				type: null,
				title: '',
				icon: null,
			},
		};
	}

	componentDidMount() {
		logger('[UsersProviders] componentDidMount');

		this.props.getProviders();
		this.props.getRoles();
	}

	componentDidUpdate(prevProps) {
		if (this.props.location !== prevProps.location && this.props.location.pathname === routers.users) {
			this.props.getProviders();
		}

	}

	onSearchTyping = (event, { newValue }) => {
		this.setState({ searchValue: newValue });
	};

	toSearchView = (value) => {
		if (value) {
			this.onSearchSubmit(value);
			this.props.history.push(routers.usersSearch);
		}
	};

	onSearchSubmit = (value) => {
		if (value) {
			this.props.getSearchUsers(getUsersOptions(value));
			this.props.getSearchProviders(getProvidersParams(value));
		}
	};

	onSearchClear = () => this.setState({ searchValue: '' });

	mainView = () => {
		const { history, providersData, rolesData, getProviders } = this.props;
		const { searchValue } = this.state;

		const onProviderClicked = (el) => {
			history.push(routers.usersProvider(el.id));
		};
		const onProviderEditClicked = (e, el) => {
			e.stopPropagation();
			const args = { id: el.id, options: getProvider() };
			// this.props.getManageProvider({ id: el.id });
			this.props.getManageProvider(args);
			if (this.props.manageProviderLoading === false) {
				history.push(routers.usersProviderEdit(el.id));
			} else {
				return <Spinner />;
			}
		};

		return (
			<AuxWrap>
				<Users
					searchValue={searchValue}
					addprovidertype={() =>
						this.modalOpen({ type: 'AddProvider', restrictClosing: true })
					}
					addusertype={() =>
						this.modalOpen({
							type: 'AddUser',
							restrictClosing: true,
						})
					}
					data={rolesData}
					providersData={providersData}
					getProviders={getProviders}
					onSearchTyping={this.onSearchTyping}
					onSearchSubmit={() => this.toSearchView(searchValue)}
					onSearchClear={this.onSearchClear}
					onProviderClicked={onProviderClicked}
					onProviderEditClicked={onProviderEditClicked}
					match={this.props.match}
					openModal={this.modalOpen}
				/>
			</AuxWrap>
		);
	};

	searchView = () => {
		return (
			<AuxWrap>
				<SearchResults
					addprovidertype={() =>
						this.modalOpen({ type: 'AddProvider', restrictClosing: true })
					}
					addusertype={() =>
						this.modalOpen({ type: 'AddUser', restrictClosing: true })
					}
					data={this.props.rolesData}
					searchValue={this.state.searchValue}
					usersData={this.props.searchUsersData}
					usersLoading={this.props.searchUsersLoading}
					providersData={this.props.searchProvidersData}
					providersLoading={this.props.searchProvidersLoading}
					history={this.props.history}
					currentUser={this.props.currentUserData}
					onSearchTyping={this.onSearchTyping}
					onSearchSubmit={() => this.onSearchSubmit(this.state.searchValue)}
					onSearchClear={this.onSearchClear}
					changeUsersStatus={this.props.changeSearchUsersStatus}
					openModal={this.modalOpen}
				/>
			</AuxWrap>
		);
	};

	providerView = (props) => {
		const {
			manageProviderData,
			manageProviderLoading,
			getManageProvider,
			history,
			currentUserData,
			changeManageUsersStatus,
			clearManageProvider,
			modal,
		} = this.props;

		const onInitHandler = (id) => {
			const args = { id: id, options: getProvider() };
			getManageProvider(args);
		};

		return (
			<AuxWrap>
				<Provider
					data={manageProviderData}
					loading={manageProviderLoading}
					match={props.match}
					history={history}
					currentUser={currentUserData}
					onInit={onInitHandler}
					changeManageUsersStatus={changeManageUsersStatus}
					openModal={this.modalOpen}
					globalModal={modal}
					clear={clearManageProvider}
				/>
			</AuxWrap>
		);
	};

	providerEditView = (props) => {
		const {
			history,
			manageProviderData,
			updateProvider,
			clearManageProvider,
			getManageProvider,
			manageProviderLoading,
		} = this.props;
		const onInitHandler = (id) => {
			getManageProvider({ id });
		};
		if (manageProviderLoading) return <Spinner />;
		else
			return (
				<AuxWrap>
					<ProviderEdit
						data={manageProviderData}
						match={props.match}
						history={history}
						onInit={onInitHandler}
						clear={clearManageProvider}
						openModal={this.modalOpen}
						updateProvider={updateProvider}
					/>
				</AuxWrap>
			);
	};

	setStatusHandler = () => {
		this.setState({ blockProviderPopup: true });
	};

	approveHandlerForToggleProviderStatus = (provider_id) => {
		try {
			this.props.blockProvider(provider_id);
			this.modalClose();
			setTimeout(() => {
				this.props.getManageProvider({ id: provider_id, options: getProvider() });
			}, 400);
		} catch (error) {
			console.error("Error in approveHandler:", error);
		}
	};

	modalView = () => {
		let modal;

		switch (this.state.modal.type) {
			case 'AddProvider':
				modal = (
					<AddProvider
						close={this.modalClose}
						history={this.props.history}
						addProvider={this.props.addProvider}
					/>
				);
				break;
			case 'AddUser':
				modal = (
					<AddUser
						providersData={this.props.providersData}
						rolesData={this.props.rolesData}
						addProvider={this.props.addProvider}
						currentUser={this.props.currentUserData}
						addUser={this.props.addUser}
						close={this.modalClose}
					/>
				);
				break;
			case 'DeleteProvider':
				modal = (
					<DeleteProvider
						modalData={this.state.modal.data}
						history={this.props.history}
						deleteProvider={this.props.deleteProvider}
						close={this.modalClose}
					/>
				);
				break;
			case 'UserDetails':
				modal = (
					<UserDetails
						type={this.state.modal.subType}
						modalData={this.state.modal.data}
						providerUsersData={this.props.manageProviderData}
						usersData={this.props.searchUsersData}
						providersData={this.props.providersData}
						rolesData={this.props.rolesData}
						rowIndex={this.state.modal.index}
						addProvider={this.props.addProvider}
						currentUser={this.props.currentUserData}
						changeUserStatus={this.props.changeManageUsersStatus}
						changeSearchUsersStatus={this.props.changeSearchUsersStatus}
						changeUserProvider={this.props.changeUserProvider}
						changeUserRole={this.props.changeUserRole}
						close={this.modalClose}
					/>
				);
				break;
			case 'BlockProvider':
				modal = (
					<BlockProvider
						data={this.state.modal.data}
						approveHandler={() => this.approveHandlerForToggleProviderStatus(this.state.modal.data.id)}
						cancelHandler={this.modalClose}
					/>
				);
				break;
			default:
				modal = 'Modal type is undefined';
				break;
		}

		return modal;
	};

	modalClose = () => {
		this.setState({
			modal: {
				index: null,
				isOpen: false,
				type: null,
			},
		});
	};

	modalOpen = ({ index, type, subType, data, restrictClosing }) => {
		ReactGA.event({
			category: 'Providers and Users',
			action: 'Click',
			label: `Open Modal ${type}`,
		});

		this.setState({
			modal: {
				index: index,
				isOpen: true,
				type: type,
				subType: subType,
				data: data,
				restrictClosing,
			},
		});
	};



	render() {
		logger('[UsersProviders] Render');

		return (
			<div className={s.page}>
				<div className={s.content}>
					<Route path={routers.users} exact render={this.mainView} />
					<Route path={routers.usersSearch} render={this.searchView} />
					<Route
						path={routers.usersProvider()}
						exact
						render={this.providerView}
					/>
					<Route
						path={routers.usersProviderEdit()}
						render={this.providerEditView}
					/>
					<Modal
						className={
							this.state.modal.type === 'AddProvider'
								? s.providerModal
								: s.modal
						}
						show={this.state.modal.isOpen}
						modalClosed={this.modalClose}
						modalTitle={this.state.modal.title}
						modalIcon={this.state.modal.icon}
						restrictClosing={this.state.modal.restrictClosing ? true : false}
					>
						{this.modalView()}
					</Modal>
				</div>
			</div>
		);
	}
}

const mapStateToProps = () => {
	const getAllManageUserState = makeGetAllManageUserState();

	return (props, state) => getAllManageUserState(props, state);
};

const mapDispatchToProps = (dispatch) => {
	return {
		getProviders: (args) => dispatch(actions.getProviders(args)),
		addProvider: (data, success, error) =>
			dispatch(actions.addProvider(data, success, error)),
		deleteProvider: (id, success, error) =>
			dispatch(actions.deleteProvider(id, success, error)),
		blockProvider: (args) => dispatch(actions.blockProvider(args)),
		updateProvider: (args) => dispatch(actions.updateProvider(args)),

		getSearchProviders: (args) => dispatch(actions.getSearchProviders(args)),
		getSearchUsers: (args) => dispatch(actions.getSearchUsers(args)),
		changeSearchUsersStatus: (args) =>
			dispatch(actions.changeSearchUsersStatus(args)),

		getManageProvider: (args) => dispatch(actions.getManageProvider(args)),

		changeManageUsersStatus: (args) =>
			dispatch(actions.changeManageUsersStatus(args)),
		clearManageProvider: () => dispatch(actions.clearManageProvider()),

		changeUserProvider: (args) => dispatch(actions.changeUserProvider(args)),
		changeUserRole: (args) => dispatch(actions.changeUserRole(args)),
		getRoles: (args) => dispatch(actions.getRoles(args)),
		addUser: (args) => dispatch(actions.addUser(args)),
	};
};

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps,
	)(withTranslation()(UsersProviders)),
);
