import React, { Component } from 'react';
import s from './style.module.scss';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import i18next from 'i18next';
import usa from '../../assets/icons/usa-icon.png';
import france from '../../assets/icons/france-icon.png';
import { connect } from 'react-redux';
import * as actions from 'store/actions';

class LanguagePopover extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedLanguage: i18next.language,
			selectedLanguageName: '',
		};
	}

	componentDidMount() {
		this.getLangName();
	}

	switchLanguage(lang) {
		i18next.changeLanguage(lang).then(() => {
			this.setState({ selectedLanguage: lang });
			this.getLangName();
			window.location.reload();
		});

		this.props.getTerms();
	}

	getLangName() {
		this.state.selectedLanguage === 'en'
			? this.setState({ selectedLanguageName: 'English' })
			: this.state.selectedLanguage === 'fr'
			  ? this.setState({ selectedLanguageName: 'Français' })
			  : this.setState({ selectedLanguageName: 'Español' });
	}

	render() {
		const { placement, rootClose } = this.props;
		const popover = (
			<Popover id='popover-basic'>
				<Popover.Content>
					<ul className={s.list}>
						<li
							className={s.listItem}
							onClick={() => this.switchLanguage('fr')}
						>
							<img src={france} alt='' />
							<span className={s.languageText}>Français</span>
						</li>
						<li
							className={s.listItem}
							onClick={() => this.switchLanguage('en')}
						>
							<img src={usa} alt='' />
							<span className={s.languageText}>English</span>
						</li>
					</ul>
				</Popover.Content>
			</Popover>
		);
		const OverlayContainer = () => (
			<OverlayTrigger
				trigger='click'
				placement={placement ? placement : 'right'}
				overlay={popover}
				rootClose={rootClose}
			>
				<div className={s.langContainer}>
					<img
						alt=''
						src={
							this.state.selectedLanguage === 'en'
								? usa
								: this.state.selectedLanguage === 'fr'
								  ? france
								  : ''
						}
					/>
					<span className={s.languageText}>
						{this.state.selectedLanguageName}
					</span>
				</div>
			</OverlayTrigger>
		);

		return <OverlayContainer />;
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		getTerms: () => dispatch(actions.getTerms()),
	};
};

export default connect(null, mapDispatchToProps)(LanguagePopover);
