import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Input } from 'components';
import i18next from 'i18next';
import s from '../style.module.scss';
import 'moment/locale/fr';

const config = [
	{
		status: 2,
		elementType: 'input',
		elementConfig: {
			type: 'radio',
			name: 'settings',
		},
		theme: 'radio',
		className: s.radio,
		label: (date) => (
			<Fragment>
				{i18next.t('publish_on_release_date')}
				<small>
					{date &&
						moment(date)
							.locale(
								i18next.language === 'fr'
									? 'fr'
									: i18next.language === 'en'
									  ? 'en'
									  : '',
							)
							.format('D MMM YYYY')}
				</small>
			</Fragment>
		),
	},
	{
		status: 4,
		elementType: 'input',
		elementConfig: {
			type: 'radio',
			name: 'settings',
		},
		theme: 'radio',
		className: s.radio,
		label: () => i18next.t('publish_for_testing'),
	},
	{
		status: 5,
		elementType: 'input',
		elementConfig: {
			type: 'radio',
			name: 'settings',
		},
		theme: 'radio',
		className: s.radio,
		label: () => i18next.t('save_as_draft'),
	},
];

const PublishingSettings = ({ status, date, onChange }) => {
	return (
		<Fragment>
			<h2 className={s.subTitle}>{i18next.t('publishing_settings')}</h2>
			<div className={s.settings}>
				{config.map((el, i) => {
					return (
						<Input
							key={i}
							elementType={el.elementType}
							elementConfig={el.elementConfig}
							theme={el.theme}
							className={el.className}
							checked={status === el.status}
							label={el.label(date)}
							changed={() => onChange(el.status)}
							newDesign={true}
						/>
					);
				})}
			</div>
		</Fragment>
	);
};

PublishingSettings.defaultProps = {
	date: null,
	onChange: () => {},
};

PublishingSettings.propTypes = {
	date: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	status: PropTypes.number,
	onChange: PropTypes.func,
};

export default PublishingSettings;
