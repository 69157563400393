import { coreInstance as axios, api, messages } from 'settings';
import { logger, toast } from 'helpers';
import * as actionTypes from '../actionTypes';

const getSearchUsersStart = () => {
	return {
		type: actionTypes.GET_SEARCH_USERS_START,
	};
};

const getSearchUsersSuccess = (data) => {
	return {
		type: actionTypes.GET_SEARCH_USERS_SUCCESS,
		data: data,
	};
};

export const clearSearchUsers = () => {
	return {
		type: actionTypes.CLEAR_SEARCH_USERS,
	};
};

export const getSearchUsers = (options, withLoader = true) => {
	return (dispatch) => {
		withLoader && dispatch(getSearchUsersStart());

		axios
			.get(api.users.index, { params: options })
			.then((response) => {
				logger('[Action] Get search users');

				dispatch(getSearchUsersSuccess(response.data.data));
			})
			.catch(() => {
				logger('[Action] Get search users fail');
			});
	};
};

const changeSearchUsersStatusStart = (data) => {
	return {
		type: actionTypes.CHANGE_SEARCH_USERS_STATUS_START,
		data: data,
	};
};

const changeSearchUsersStatusSuccess = (data) => {
	return {
		type: actionTypes.CHANGE_SEARCH_USERS_STATUS_SUCCESS,
		data: data,
	};
};

export const changeSearchUsersStatus = ({ id, index, status, callback }) => {
	const data = {
		id: id,
		index: index,
		status: status,
	};

	return (dispatch) => {
		dispatch(changeSearchUsersStatusStart(data));
		axios
			.post(api.users.index + `/${id}`, { status: status })
			.then(() => {
				logger('[Action] Change search users status');

				dispatch(changeSearchUsersStatusSuccess(data));
				toast.success(messages.userUpdated);
				callback && callback();
			})
			.catch((err) => {
				if (err?.response?.status === 400) {
					toast.error(err?.response?.data?.message);
				}
				logger('[Action] Change search users status fail');
			});
	};
};
