import * as actionTypes from './actionTypes';
import {
	coreInstance as axios,
	patientInstance,
	api,
	messages,
} from 'settings';
import { logger, toast } from 'helpers';
import i18next from 'i18next';
import { constants } from 'helpers';

// Demographic Data
export const getPatientDemographicInfoStart = () => {
	return {
		type: actionTypes.GET_PATIENTDEMOGRAPHIC_INFO_START,
	};
};

export const getPatientDemographicInfo = (args = {}) => {
	const { patientId } = args;

	return async (dispatch) => {
		dispatch(getPatientDemographicInfoStart());
		await patientInstance
			.get(`${api.patient_details.get_demographic_info}/${patientId}`)
			.then((response) => {
				dispatch(getPatientDemographicInfoSuccess(response.data.data));
			})
			.catch((err) => {
				const res = {
					success: true,
					data: {},
					message: err.message,
				};
				// console.error('In error', res)
				dispatch(getPatientDemographicInfoSuccess(res.data));
				logger(`[Error] Failed fetching Demographic Data" ${err}`);
			});
	};
};
export const setPatientId = (data) => {
	return {
		type: actionTypes.SET_VALUE,
		data: data,
	};
};

export const getPatientDemographicInfoSuccess = (data) => {
	return {
		type: actionTypes.GET_PATIENTDEMOGRAPHIC_INFO_SUCCESS,
		data: data,
	};
};

// Errors Data
export const getPatientDeviceErrorsStart = () => {
	return {
		type: actionTypes.GET_PATIENT_DEVICE_ERRORS_INFO_START,
	};
};

export const getPatientDeviceErrors = (args) => {
	let { search, searchFields, limit, sortedBy, orderBy } = args;
	const params = {
		search: {
			concentrator_profile_id: search?.concentrator_profile_id,
		},
		searchFields: searchFields || {
			concentrator_profile_id: 'in',
			code: 'in',
		},
		limit: limit,
		sortedBy: sortedBy,
		orderBy: orderBy,
	};

	if (search?.code) {
		params.search.code = search?.code;
	}
	if (search?.error_id) {
		params.searchFields.error_id = 'in';
		params.search.error_id = search?.error_id;
	}
	return async (dispatch) => {
		dispatch(getPatientDeviceErrorsStart());
		await axios
			.get(`${api.patient_details.get_device_errors}`, { params })
			.then((response) => {
				dispatch(getPatientDeviceErrorsSuccess(response?.data?.data));
			})
			.catch((err) => {
				const res = {
					success: true,
					data: [],
					message: err.message,
				};
				dispatch(getPatientDeviceErrorsSuccess(res.data));
				logger(`[Error] Failed fetching Demographic Data" ${err}`);
			});
	};
};

export const getPatientDeviceErrorsSuccess = (data) => {
	return {
		type: actionTypes.GET_PATIENT_DEVICE_ERRORS_INFO_SUCCESS,
		data: data,
	};
};

// Resolve errors
export const errorResolvingStart = () => {
	return {
		type: actionTypes.ERROR_RESOLVING_START,
	};
};

export const errorResolvingSuccess = () => {
	return {
		type: actionTypes.ERROR_RESOLVING_SUCCESS,
	};
};

export const resolveErrors = (args = {}) => {
	const { concentrator_profile_id, getErrorRequest, modalCloseHandler } = args;
	const postData = {
		error_id: [],
		concentrator_profile_id: concentrator_profile_id,
	};

	return (dispatch) => {
		dispatch(errorResolvingStart());

		axios
			.post(`${api.patient_details.post_resolve_errors}`, postData)
			.then(() => {
				logger('[Action] Reset password');
				dispatch(errorResolvingSuccess());
				toast.success(messages.resolved, {
					style: { fontSize: '16px' },
				});
				dispatch(getPatientDeviceErrors(getErrorRequest));
			})
			.then(() => modalCloseHandler())
			.catch(() => {
				// TODO: Handle error
				logger('[Action] Reset password fail');
				toast.error(messages.wentWrong, {
					style: { fontSize: '16px' },
				});
			});
	};
};

const getConcentratorProfilesStart = () => {
	return {
		type: actionTypes.GET_CONCENTRATORPROFILES_START,
	};
};

const getConcentratorProfilesSuccess = (data) => {
	return {
		type: actionTypes.GET_CONCENTRATORPROFILES_SUCCESS,
		data: data,
	};
};

const getConcentratorLocationsByProfileIdSuccess = (data) => {
	return {
		type: actionTypes.GET_CONCENTRATORLOCATIONS_BY_PROFILEID_SUCCESS,
		data: data,
	};
};

const checkUpdateSuccess = (data) => {
	return {
		type: actionTypes.CHECK_UPDATE_SUCCESS,
		data: data,
	};
};

export const getConcentratorProfiles = (concentratorProfileId, patientId) => {
	return (dispatch) => {
		dispatch(getConcentratorProfilesStart());
		const request = {
			uri: `${api.concentrator_profiles.index}/${concentratorProfileId}`,
			params: {
				with: 'PatientDetails',
				append: {
					patient_id: patientId,
				},
			},
		};

		axios
			.get(request.uri, { params: request.params })
			.then(async (response) => {
				logger('[Action] Get Concentrator Profiles');
				dispatch(getConcentratorProfilesSuccess(response.data));
				await dispatch(checkUpdate(response.data));
			})
			.catch(() => {
				toast.error(messages.wentWrong);
				logger('[Action] Get Concentrator Profiles fail');
			});
	};
};

export const getConcentratorLocationsByProfileId = (concentratorProfileId) => {
	return (dispatch) => {
		const request = {
			uri: `${api.concentrator_locations.index}/${concentratorProfileId}`,
		};
		axios
			.get(request.uri)
			.then((response) => {
				logger('[Action] Get Concentrator Profile Location');
				dispatch(
					getConcentratorLocationsByProfileIdSuccess(response.data.data),
				);
			})
			.catch(() => {
				toast.error(messages.wentWrong);
				logger('[Action] Get Concentrator Profile Location fail');
			});
	};
};

export const fetchConcentratorData = (concentratorProfileId) => (dispatch) => {
	const options = {
		with: ['concentratorErrorRecallLast.concentratorError'],
		append: 'all_healthcheck_parameters',
	};

	dispatch({ type: actionTypes.FETCH_CONCENTRATOR_DATA });

	axios
		.get(`${api.concentrator_profiles.index}/${concentratorProfileId}`, {
			params: options,
		})
		.then((response) => {
			const data = response.data.data;
			dispatch({
				type: actionTypes.SET_CONCENTRATOR_DATA,
				payload: {
					data: data,
				},
			});
		})
		.catch((err) => {
			dispatch({
				type: actionTypes.ERROR_CONCENTRATOR_DATA,
				payload: err.message,
			});
		});
};

export const sendUpdateNotificationStart = () => {
	return {
		type: actionTypes.SEND_UPDATE_NOTIFICATION_START,
	};
};

export const sendUpdateNotificationSuccess = (data) => {
	return {
		type: actionTypes.SEND_UPDATE_NOTIFICATION_SUCCESS,
		data: data,
	};
};

export const sendUpdateNotificationError = (error) => {
	return {
		type: actionTypes.SEND_UPDATE_NOTIFICATION_ERROR,
		error: error,
		data: true,
	};
};

export const sendUpdateNotification = ({ SN, type, concentratorProfileId }) => {
	return async (dispatch) => {
		dispatch(sendUpdateNotificationStart());

		const messages = {
			softwareUpdateSuccess: i18next.t('push_notification_has_been_sent'),
			softwareUpdateError: i18next.t('device_info_messages_error'),
		};

		let params = {
			title: i18next.t('update_software'),
			body: i18next.t('new_version_is_available_to_install'),
			concentrator_profiles_filter: `search=serial_number:${SN};concentrator_profile_id:${concentratorProfileId}&searchFields=serial_number:in;concentrator_profile_id:in`,
			push_type_id: constants.PUSH_TYPE_ID_UPDATE,
		};

		if (type === 'logs') {
			params = {
				title: i18next.t('transfer_data'),
				body: i18next.t('please_perform_data_transfer'),
				concentrator_profiles_filter: `search=serial_number:${SN};concentrator_profile_id:${concentratorProfileId}&searchFields=serial_number:in;concentrator_profile_id:in`,
				push_type_id: constants.PUSH_TYPE_ID_DATA_TRANSFER,
			};
		}

		try {
			// Make the Axios POST request
			await axios.post(api.push_notification_history.index, params);
			dispatch(sendUpdateNotificationSuccess(true));
			toast.success(messages.softwareUpdateSuccess);
		} catch (error) {
			dispatch(sendUpdateNotificationError(error.message));
			toast.error(messages.softwareUpdateError);
		}
	};
};

export const setActiveTabIndex = (index) => ({
	type: actionTypes.SET_ACTIVE_TAB_INDEX,
	payload: index,
});

export const setActiveTabName = (name) => ({
	type: actionTypes.SET_ACTIVE_TAB_NAME,
	payload: name,
});

export const setTableData = (data) => ({
	type: actionTypes.SET_TABLE_DATA,
	payload: data,
});

export const setLoading = (isLoading) => ({
	type: actionTypes.SET_LOADING,
	payload: isLoading,
});

export const fetchHistoryData = (concentratorProfileId) => (dispatch) => {
	dispatch(setLoading(true));
	const request = {
		uri: api.concentrator_firmware_histories.index,
		params: {
			with: 'concentratorFirmware',
			search: {
				concentrator_profile_id: `${concentratorProfileId}`,
			},
			searchFields: {
				concentrator_profile_id: `${concentratorProfileId}`,
			},
			orderBy: 'id',
			sortedBy: 'desc',
			limit: 100,
		},
	};

	dispatch(setActiveTabName('software'));
	if (concentratorProfileId) {
		axios
			.get(request.uri, { params: request.params })
			.then((response) => {
				dispatch(setTableData(response.data.data));
				dispatch(setLoading(false));
				dispatch(fetchHistoryDataSuccess(response.data.data));
			})
			.catch((err) => {
				console.log(err);
				dispatch(setLoading(false));
				dispatch(fetchHistoryDataFailure(err.message));
			});
	} else {
		dispatch(setLoading(false));
	}
};

export const fetchHistoryDataSuccess = (data) => ({
	type: actionTypes.FETCH_HISTORY_DATA_SUCCESS,
	payload: data,
});

export const fetchHistoryDataFailure = (error) => ({
	type: actionTypes.FETCH_HISTORY_DATA_FAILURE,
	payload: error,
});

export const historyHandler =
	(name, index, concentratorProfileId, concentratorModelId) => (dispatch) => {
		dispatch(setLoading(true));
		let request = '';
		if (name === 'ErrorRecall') {
			request = {
				uri: api.concentrator_error_recalls.requestayear,
				params: {
					search: {
						concentrator_model_id: `${concentratorModelId}`,
						concentrator_profile_id: `${concentratorProfileId}`,
					},
				},
			};
		} else {
			request = {
				uri: api.concentrator_firmware_histories.index,
				params: {
					with: 'concentratorFirmware',
					search: {
						concentrator_profile_id: `${concentratorProfileId}`,
					},
					searchFields: {
						concentrator_profile_id: `${concentratorProfileId}`,
					},
					orderBy: 'id',
					sortedBy: 'desc',
					limit: 100,
				},
			};
		}

		if (name === 'pairing') {
			request.uri = api.concentrator_mobile_device_pairing_history.index;
			request.params.with = 'deviceName';
		}

		if (name === 'connection') {
			request.uri = api.concentrator_connection_history.index;
			request.params.with = null;
		}

		dispatch(setActiveTabIndex(index));
		dispatch(setActiveTabName(name));
		if (concentratorModelId && concentratorProfileId) {
			axios
				.get(request.uri, { params: request.params })
				.then((response) => {
					dispatch(setTableData(response.data.data));
					dispatch(fetchHistoryDataSuccess(response.data.data));
					dispatch(setLoading(false));
				})
				.catch((err) => {
					console.log(err);
					dispatch(setLoading(false));
					dispatch(fetchHistoryDataFailure(err.message));
				});
		} else {
			dispatch(setLoading(false));
		}
	};

export const getTroubleShootingGuide = (args) => {
	let { error_id } = args;
	const params = { error_id };

	return async (dispatch) => {
		dispatch(getTroubleShootingGuideStart());
		await axios
			.get(`${api.patient_details.get_troubleshooting_guide}`, { params })
			.then((response) => {
				let data =
					response?.data?.data && response?.data?.data.length
						? response?.data?.data[0]
						: {};
				dispatch(getTroubleShootingGuideSuccess(data));
			})
			.catch((err) => {
				const res = {
					success: true,
					data: {},
					message: err.message,
				};
				dispatch(getTroubleShootingGuideSuccess(res.data));
				logger(`[Error] Failed fetching Demographic Data" ${err}`);
			});
	};
};

export const getTroubleShootingGuideStart = () => {
	return {
		type: actionTypes.GET_TROUBLE_SHOOTING_GUIDE_START,
	};
};

export const getTroubleShootingGuideSuccess = (data) => {
	return {
		type: actionTypes.GET_TROUBLE_SHOOTING_GUIDE_SUCCESS,
		data: data,
	};
};

export const resetPatientData = () => ({
	type: actionTypes.RESET_PATIENT_DATA,
});
export const sendNotificationStart = () => {
	return {
		type: actionTypes.SEND_NOTIFICATION_START,
	};
};

export const sendNotificationSuccess = () => {
	return {
		type: actionTypes.SEND_NOTIFICATION_SUCCESS,
	};
};

export const sendNotificationFail = (error) => {
	return {
		type: actionTypes.SEND_NOTIFICATION_FAIL,
		error: error,
	};
};

export const sendNotification = (params, closeNotification) => {
	return (dispatch) => {
		dispatch(sendNotificationStart());

		axios
			.post(api.push_notification_history.index, params)
			.then(() => {
				toast.success(messages.notificationSent);
				dispatch(sendNotificationSuccess());
				dispatch(closeNotification);
			})
			.catch((error) => {
				toast.error(messages.deviceInfoError);
				dispatch(sendNotificationFail(error));
				dispatch(closeNotification);
			});
	};
};
export const checkUpdate = (profileData) => {
	return (dispatch) => {
		const groupsData = profileData.data;
		var isAllowUpdate = false;
		profileData.data.isAllowUpdate = isAllowUpdate;
		if (groupsData) {
			const params = {
				concentrator_model_id: groupsData?.concentrator_model_id,
				version: groupsData?.firmware_version,
			};
			const headers = {
				'X-CONCENTRATOR-SN': groupsData?.serial_number,
				'X-CONCENTRATOR-MODEL-ID': groupsData?.concentrator_model_id,
			};
			axios
				.get(api.concentrator_firmwares.latest, { params, headers })
				.then((response) => {
					logger('[Action] Get firmwares details');
					// eslint-disable-next-line no-prototype-builtins
					if (response?.data?.hasOwnProperty('data')) {
						isAllowUpdate = true;
						dispatch(checkUpdateSuccess(isAllowUpdate));
					}
					dispatch(getConcentratorProfilesSuccess(profileData));
				})
				.catch(() => {
					logger('[Action] Get firmwares details fail');
				});
		}
	};
};
